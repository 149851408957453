<template>
  <div class="bg-white shadow overflow-hidden sm:rounded-lg">
    <div
      class="flex justify-between px-4 py-4 text-lg leading-6 font-medium text-gray-900 font-bold cursor-pointer"
    >
      <div>
        <h3>
          {{ title }}
        </h3>
        <p class="text-xs">
          {{ subtitle }}
        </p>
      </div>
      <div class="flex">
        <slot name="header" />
        <div class="w-10 ml-2" @click="toggleCollapse">
          <ChevronDownIcon v-if="collapsed" />
          <ChevronUpIcon v-else />
        </div>
      </div>
    </div>
    <div v-if="!collapsed" class="flex flex-col border-t border-gray-200 bg-white">
      <slot />
    </div>
  </div>
</template>

<script>
import { ChevronDownIcon, ChevronUpIcon } from "@heroicons/vue/outline";

export default {
  components: { ChevronDownIcon, ChevronUpIcon },

  props: {
    open: {
      type: Boolean,
      default: true,
      required: false,
    },
    title: {
      type: String,
      required: false,
      default: "",
    },
    subtitle: {
      type: String,
      required: false,
      default: "",
    },
  },

  data() {
    return {
      collapsed: false,
    };
  },

  methods: {
    toggleCollapse: function () {
      this.collapsed = !this.collapsed;
    },
  },

  watch: {
    open: {
      immediate: true,
      handler() {
        this.collapsed = !this.open;
      },
    },
  },
};
</script>
