<template>
  <div class="mb-6 grid grid-cols-1">
    <div
      class="px-4 py-5 bg-white shadow rounded-lg sm:px-5 sm:py-3 cursor-pointer hover:shadow-md font-bold"
    >
      <p class="text-gray-900 text-md">Récapitulatif</p>
      <hr class="my-2 border-gray-300" />
      <div class="grid grid-rows-4 md:grid-cols-4 md:grid-rows-none mt-2">
        <div>
          <p class="text-gray-700 font-normal">À percevoir d'Estaly</p>
          <p class="text-green-700 font-light text-2xl">
            {{ formatPrice(this.amountOwedToSubject) }}
          </p>
        </div>
        <div>
          <p class="text-gray-700 font-normal">À Payer</p>
          <p class="text-red-700 font-light text-2xl">
            {{ formatPrice(this.amountOwedToEstaly) }}
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { formatPrice } from "@/utils/price_formatter";

export default {
  props: ["amountOwedToEstaly", "amountOwedToSubject"],
  methods: {
    formatPrice
  }
};
</script>
