import StoreService from "../../api/services/store";

const initialState = {
  isLoading: false,
  allProducts: [],
  totalNumberOfProducts: null, // Total number of products that matches the filter criterias
  totalNumberOfProductsApproved: null, // Total number of parent products that are approved
  approvedProductsIds: [], // ids of the approved products that matches the filter criterias
  availableFilters: {
    productTypes: [],
    vendors: [],
    tags: [],
    offerStatuses: [],
    approvalStatuses: [],
  },
  selectedFilters: {
    productTypes: [],
    vendors: [],
    tags: [],
    offerStatuses: [],
    approvalStatuses: [],
  },
  search: "",
  selectedStoreId: "",
};

export default {
  namespaced: true,

  state: initialState,

  getters: {
    isSearchInputNotEmpty: (state) => {
      return state.search !== null && state.search !== "";
    },

    selectedStoreId: (state) => {
      return state.selectedStoreId;
    },

    isLoading: (state) => {
      return state.isLoading;
    },
  },

  mutations: {
    setProducts(state, products) {
      state.allProducts = products;
    },

    setAvailableFilters(
      state,
      { productTypes, vendors, tags, offerStatuses, approvalStatuses }
    ) {
      state.availableFilters.productTypes = productTypes;
      state.availableFilters.vendors = vendors;
      state.availableFilters.tags = tags;
      state.availableFilters.offerStatuses = offerStatuses;
      state.availableFilters.approvalStatuses = approvalStatuses;
    },

    setTotalNumberOfProducts(state, totalNumberOfProducts) {
      state.totalNumberOfProducts = totalNumberOfProducts;
    },

    setTotalNumberOfProductsApproved(state, totalNumberOfProductsApproved) {
      state.totalNumberOfProductsApproved = totalNumberOfProductsApproved;
    },

    setApprovedProductsIds(state, approvedProductsIds) {
      state.approvedProductsIds = approvedProductsIds;
    },

    setIsLoading(state, val) {
      state.isLoading = val;
    },

    setFilter(state, { filterName, filterOptions }) {
      state.selectedFilters[filterName] = filterOptions;
    },

    setSearch(state, searchString) {
      state.search = searchString;
    },

    resetFilters(state) {
      for (const filter of Object.keys(state.selectedFilters)) {
        state.selectedFilters[filter] = [];
      }
    },

    setSelectedStoreId(state, storeId) {
      state.selectedStoreId = storeId;
    },

    resetState(state) {
      state.isLoading = initialState.isLoading;
      state.allProducts = initialState.allProducts;
      state.totalNumberOfProducts = initialState.totalNumberOfProducts;
      state.totalNumberOfProductsApproved =
        initialState.totalNumberOfProductsApproved;
      state.approvedProductsIds = initialState.approvedProductsIds;
      state.availableFilters = initialState.availableFilters;
      state.selectedFilters = initialState.selectedFilters;
      state.search = initialState.search;
      state.selectedStoreId = initialState.selectedStoreId;
    },
  },

  actions: {
    updateStoreId({ commit }, storeId) {
      commit("setSelectedStoreId", storeId);
    },

    async getProducts(
      { state, commit },
      {
        storeId,
        online,
        offline,
        title,
        source,
        parents,
        variants,
        page,
        categories,
      }
    ) {
      try {
        const response = await StoreService.getProducts(
          storeId,
          state.selectedFilters.productTypes,
          state.selectedFilters.vendors,
          state.selectedFilters.offerStatuses,
          state.selectedFilters.approvalStatuses,
          state.selectedFilters.tags,
          state.search,
          title,
          source,
          parents,
          variants,
          online,
          offline,
          page,
          categories
        );

        const {
          products,
          productTypes,
          vendors,
          tags,
          offerStatuses,
          approvalStatuses,
          totalNumberOfProducts,
          totalNumberOfProductsApproved,
          approvedProductsIds,
        } = {
          ...response.data,
        };

        commit("setProducts", products);
        commit("setTotalNumberOfProducts", totalNumberOfProducts);
        commit(
          "setTotalNumberOfProductsApproved",
          totalNumberOfProductsApproved
        );
        commit("setApprovedProductsIds", approvedProductsIds);
        commit("setAvailableFilters", {
          productTypes,
          vendors,
          tags,
          offerStatuses,
          approvalStatuses,
        });
      } catch (error) {
        return Promise.reject(error);
      }
    },

    reinitializeStoreState({ commit }) {
      commit("resetState");
    },

    updateFilter(
      { commit, dispatch },
      { filterName, filterOptions, storeId, page, online, parents }
    ) {
      commit("setFilter", { filterName, filterOptions });
      dispatch("getProducts", {
        storeId: storeId,
        page: page,
        online: online,
        parents: parents,
      });
    },

    updateSearch(
      { commit, dispatch },
      { searchString, storeId, page, online, parents }
    ) {
      commit("setSearch", searchString);
      dispatch("getProducts", {
        storeId: storeId,
        page: page,
        online: online,
        parents: parents,
      });
    },

    resetSearch({ commit }) {
      commit("setSearch", "");
    },

    resetFilters({ commit }) {
      commit("resetFilters");
    },
  },
};
