<template>
  <SimulationPos
    v-if="currentScreenSize < screens.lg"
    :productPrice="product.productPrice"
    :productCategoryValue="product.productCategory?.value"
    :offeredMonths="offeredMonths"
    class="block mb-4"
  />
  <VeeForm
    v-slot="{ handleSubmit }"
    :validation-schema="schema"
    as="div"
    ref="leadCreationForm"
  >
    <form @submit="handleSubmit($event, submitLeadCreation)">
      <div class="sm:inline lg:flex">
        <div class="lg:w-4/5 lg:mr-4">
          <div class="mb-2 bg-white p-4 rounded-md">
            <p class="text-xl font-heading-1 pb-2 tracking-tight text-gray-900">
              {{ $t("Seller informations") }}
            </p>
            <div>
              <div>
                <OfflineSellerCombobox
                  :sellerCompleteName="sellerCompleteName"
                  @selectedSeller="handleSelectSeller"
                />
                <div class="mt-1">
                  <Field
                    id="sellerCompleteName"
                    name="sellerCompleteName"
                    type="text"
                    class="shadow-sm focus:ring-primary focus:border-primary w-full border-gray-300 rounded-md hidden"
                    placeholder="John Doe"
                    v-model="sellerCompleteName"
                  />
                  <ErrorMessage
                    name="sellerCompleteName"
                    class="mt-2 text-sm text-red-600"
                  />
                </div>
              </div>
            </div>
          </div>
          <div>
            <CategorySelector
              @selectProductCategory="setProductCategory"
              :categories="categories"
            />
            <div class="bg-white p-4 rounded-md">
              <p
                class="text-xl font-heading-1 pb-2 tracking-tight text-gray-900"
              >
                {{ $t("Product informations") }}
              </p>
              <div>
                <OfflineProductsCombobox
                  @selectProduct="handleSelectProduct"
                  :product-name="product.productName"
                  :productCategory="product.productCategory"
                />
                <div class="mt-1">
                  <Field
                    id="productName"
                    name="product.productName"
                    type="text"
                    class="shadow-sm focus:ring-primary focus:border-primary w-full border-gray-300 rounded-md hidden"
                    placeholder="RIESE & MÜLLER - MULTICHARGER MIXTE 51 - GT Rohloff"
                    v-model="product.productName"
                  />
                  <ErrorMessage
                    name="product.productName"
                    class="mt-2 text-sm text-red-600"
                  />
                </div>
              </div>

              <div class="grid grid-cols-2 gap-x-4 gap-y-3">
                <div class="flex-1 mt-1">
                  <label for="productPrice" class="flex text-sm text-gray-700">
                    <div v-if="bikesContracts">
                      {{ $t("Product price + accessories incl. VAT") }}
                    </div>
                    <div v-else>{{ $t("Product price") }}</div>
                    <span class="ml-1 text-red-600">*</span>
                  </label>
                  <div class="mt-1 relative rounded-md shadow-sm">
                    <div
                      class="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none"
                    >
                      <span class="text-gray-500 sm:text-sm"> € </span>
                    </div>
                    <Field
                      id="productPrice"
                      name="product.productPrice"
                      type="text"
                      class="pl-7 pr-12 shadow-sm focus:ring-primary focus:border-primary block w-full sm:text-sm border-gray-300 rounded-md"
                      placeholder="3000,0"
                      aria-describedby="price-currency"
                      @keydown="blockEnterBehavior"
                      v-model="productPriceFormatted"
                    />
                    <div
                      class="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none"
                    >
                      <span
                        class="text-gray-500 sm:text-sm"
                        id="price-currency"
                      >
                        EUR
                      </span>
                    </div>
                  </div>
                  <ErrorMessage
                    name="product.productPrice"
                    class="mt-2 text-sm text-red-600"
                  />
                </div>
                <div class="flex-1 mt-1">
                  <label
                    for="productReference"
                    class="flex items-center text-sm text-gray-700"
                  >
                    <div v-if="electronicsContracts">
                      {{ $t("Serial number or IMEI") }}
                    </div>
                    <div v-else>{{ $t("Product reference") }}</div>
                    <span class="ml-1 text-red-600">*</span>
                  </label>
                  <div class="mt-1">
                    <Field
                      id="productReference"
                      name="product.productReference"
                      type="text"
                      class="shadow-sm focus:ring-primary focus:border-primary block w-full sm:text-sm border-gray-300 rounded-md"
                      placeholder="861536030196001"
                      @keydown="blockEnterBehavior"
                      v-model="product.productReference"
                    />
                    <ErrorMessage
                      name="product.productReference"
                      class="mt-2 text-sm text-red-600"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div class="mt-2 bg-white p-4 rounded-md">
              <p
                class="text-xl font-heading-1 pb-2 tracking-tight text-gray-900"
              >
                {{ $t("Client informations") }}
              </p>
              <div class="grid grid-cols-2 gap-x-4 gap-y-3">
                <div class="flex-1">
                  <label for="firstName" class="block text-sm text-gray-700"
                    >{{ $t("First Name") }}<span class="ml-1 text-red-600">*</span></label
                  >
                  <Field
                    type="text"
                    name="customer.firstName"
                    id="firstName"
                    autocomplete="given-name"
                    class="shadow-sm focus:ring-primary focus:border-primary block w-full sm:text-sm border-gray-300 rounded-md"
                    placeholder="John"
                    @keydown="blockEnterBehavior"
                    v-model="customer.firstName"
                  />
                  <ErrorMessage
                    name="customer.firstName"
                    class="mt-2 text-sm text-red-600"
                  />
                </div>
                <div class="flex-1">
                  <label for="lastName" class="block text-sm text-gray-700"
                    >{{ $t("Last Name") }}<span class="ml-1 text-red-600">*</span></label
                  >
                  <Field
                    type="text"
                    name="customer.lastName"
                    id="lastName"
                    autocomplete="family-name"
                    class="shadow-sm focus:ring-primary focus:border-primary block w-full sm:text-sm border-gray-300 rounded-md"
                    placeholder="Doe"
                    @keydown="blockEnterBehavior"
                    v-model="customer.lastName"
                  />
                  <ErrorMessage
                    name="customer.lastName"
                    class="mt-2 text-sm text-red-600"
                  />
                </div>
                <div class="flex-1 col-span-2">
                  <label for="email" class="block text-sm text-gray-700"
                    >{{ $t("Customer email")
                    }}<span class="ml-1 text-red-600">*</span></label
                  >
                  <div class="mt-1">
                    <Field
                      id="email"
                      name="customer.email"
                      type="email"
                      class="shadow-sm focus:ring-primary focus:border-primary block w-full sm:text-sm border-gray-300 rounded-md"
                      placeholder="customer@gmail.com"
                      @keydown="blockEnterBehavior"
                      v-model="customer.email"
                    />
                    <ErrorMessage
                      name="customer.email"
                      class="mt-2 text-sm text-red-600"
                    />
                  </div>
                </div>
                <div class="flex-1">
                  <label for="phoneNumber" class="block text-sm text-gray-700">
                    {{ $t("Customer phone number")
                    }}<span class="ml-1 text-red-600">*</span>
                  </label>
                  <div class="mt-1">
                    <Field
                      id="phoneNumber"
                      name="customer.phoneNumber"
                      type="tel"
                      class="shadow-sm focus:ring-primary focus:border-primary block w-full sm:text-sm border-gray-300 rounded-md"
                      placeholder="+33 6 99 49 75 90"
                      @keydown="blockEnterBehavior"
                      v-model="customer.phoneNumber"
                    />
                    <ErrorMessage
                      name="customer.phoneNumber"
                      class="mt-2 text-sm text-red-600"
                    />
                  </div>
                </div>
                <div class="flex-1">
                  <label for="birthDate" class="block text-sm text-gray-700">
                    {{ $t("Birth date") }}
                    <span class="ml-1 text-red-600">*</span>
                  </label>
                  <Field
                    type="date"
                    name="customer.birthDate"
                    id="birthDate"
                    autocomplete="birth-date"
                    class="shadow-sm focus:ring-primary focus:border-primary block w-full sm:text-sm border-gray-300 rounded-md"
                    placeholder="01/01/1970"
                    @keydown="blockEnterBehavior"
                    v-model="customer.birthDate"
                  />
                  <ErrorMessage
                    name="customer.birthDate"
                    class="mt-2 text-sm text-red-600"
                  />
                </div>
                <div class="flex-1">
                  <label for="birthCity" class="block text-sm text-gray-700">
                    {{ $t("Birth city") }}
                    <span class="ml-1 text-red-600">*</span>
                  </label>
                  <Field
                    type="text"
                    name="customer.birthCity"
                    id="birthCity"
                    class="shadow-sm focus:ring-primary focus:border-primary block w-full sm:text-sm border-gray-300 rounded-md"
                    placeholder="Paris"
                    @keydown="blockEnterBehavior"
                    v-model="customer.birthCity"
                  />
                  <ErrorMessage
                    name="customer.birthCity"
                    class="mt-2 text-sm text-red-600"
                  />
                </div>
                <div class="flex-1">
                  <label for="birthCountry" class="block text-sm text-gray-700">
                    {{ $t("Birth country") }}
                    <span class="ml-1 text-red-600">*</span>
                  </label>
                  <Field
                    type="text"
                    name="customer.birthCountry"
                    id="birthCountry"
                    class="shadow-sm focus:ring-primary focus:border-primary block w-full sm:text-sm border-gray-300 rounded-md"
                    placeholder="France"
                    @keydown="blockEnterBehavior"
                    v-model="customer.birthCountry"
                  />
                  <ErrorMessage
                    name="customer.birthCountry"
                    class="mt-2 text-sm text-red-600"
                  />
                </div>
                <GoogleAddress
                  class="flex-1"
                  @newAddress="newAddress"
                  :addressError="address.addressError"
                />
                <div class="flex-1">
                  <label for="addressOther" class="block text-sm text-gray-700"
                    >{{ $t("Additional address") }}</label
                  >
                  <Field
                    type="text"
                    name="address.other"
                    id="addressOther"
                    autocomplete="address-other"
                    class="shadow-sm focus:ring-primary focus:border-primary block w-full sm:text-sm border-gray-300 rounded-md"
                    placeholder="Résidence, Etage..."
                    @keydown="blockEnterBehavior"
                    v-model="address.other"
                  />
                  <ErrorMessage
                    name="address.other"
                    class="mt-2 text-sm text-red-600"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="lg:w-2/5 mt-4 lg:mt-0" v-if="currentScreenSize >= screens.lg">
          <SimulationPos
            :productPrice="product.productPrice"
            :productCategoryValue="product.productCategory?.value"
            :offeredMonths="offeredMonths"
          />
          <ContractDetailsSection
            :categories="categories"
            :productCategoryValue="product.productCategory?.value"
          />
          <SubmitFormButtons
            @sendTextMessage="sendTextMessage"
            @paymentInScreen="handlePaymentInScreen"
            @recordASale="handleSaleRecord"
          />
        </div>
      </div>
    </form>
  </VeeForm>
  <SaleCreation
    :isOpen="leadConfiguration.isSaleCreationOpen"
    :saleRecording="saleRecording"
    :customer="customer"
    :product="product"
    :address="address"
    :sellerId="sellerId"
    @closeSaleModal="closeSaleModal"
  />
  <OfferPayment
    :isOpen="leadConfiguration.isPaymentOpen"
    :lead="lead"
    :product="product"
    :customer="customer"
    @closePaymentModal="closePaymentModal"
    @updateOffer="handleUpdateOffer"
  />
</template>

<script>
import { ref } from "vue";
import { ErrorMessage, Field, Form as VeeForm } from "vee-validate";
import { isPresent } from "@/utils/validation";
import { schema } from "@/views/pos/home/utils/validation";
import { mapActions, mapMutations, mapState } from "vuex";

import OfflineProductsCombobox from "@/components/combobox/OfflineProductsCombobox.vue";
import CategorySelector from "@/components/selector/CategorySelector.vue";
import SubmitFormButtons from "@/views/pos/home/form/submit/SubmitFormButtons.vue";
import SimulationPos from "@/views/pos/home/form/simulation/SimulationPos.vue";
import SaleCreation from "@/views/pos/home/form/salecreation/SaleCreation.vue";
import OfferPayment from "@/views/pos/home/form/payment/OfferPayment.vue";
import SaleService from "@/api/services/sale";
import OfflineSellerCombobox from "@/components/combobox/OfflineSellerCombobox.vue";
import ContractDetailsSection from "@/views/pos/home/form/contracts/ContractDetailsSection.vue";
import GoogleAddress from "@/components/address/GoogleAddress";

import LeadService from "@/api/services/lead";
import { EventBus } from "@/utils/event_bus";
import { screenSize, screens } from '@/utils/breakpoints.js';
import { formatResponse } from "@/utils/backend_response_formatter";

export default {
  components: {
    ContractDetailsSection,
    OfflineSellerCombobox,
    OfflineProductsCombobox,
    VeeForm,
    Field,
    ErrorMessage,
    SubmitFormButtons,
    SimulationPos,
    SaleCreation,
    OfferPayment,
    GoogleAddress,
    CategorySelector,
  },
  props: ["categories", "marketplace", "offeredMonths"],

  mounted() {
    this.product.productCategory = ref(this.categories[0]);
  },

  data() {
    return {
      screens,
      schema,
      customer: {
        email: "",
        phoneNumber: "",
        firstName: "",
        lastName: "",
        birthDate: "",
        birthCity: "",
        birthCountry: "",
      },
      address: {
        street: "",
        city: "",
        province: "",
        country: "",
        zipCode: "",
        other: "",
        addressError: false,
      },
      product: {
        productName: "",
        productPrice: "",
        productReference: "",
        productCategory: this.categories[0],
      },
      lead: {
        id: "",
        uniqueToken: "",
        subscriptionLink: "",
        offers: [],
        discount: "",
        invitationSentByEstaly: true,
      },
      leadConfiguration: {
        recordASale: false,
        paymentInScreen: false,
        isSaleCreationOpen: false,
        isPaymentOpen: false,
      },
      saleRecording: {
        contractDetails: [],
        monthlyPrice: "",
      },
      sellerCompleteName: "",
      sellerId: "",
    };
  },

  computed: {
    ...mapState("store", ["isLoading"]),

    bikesContracts() {
      return this.product.productCategory?.value === "bike";
    },

    electronicsContracts() {
      return (
        this.product.productCategory?.value === "home_plan" ||
        this.product.productCategory?.value === "smartphones" ||
        this.product.productCategory?.value === "smartphone_home_plan"
      );
    },

    productPriceFormatted: {
      get() {
        return this.product.productPrice == null
          ? ""
          : this.product.productPrice.toString().replace(".", ",");
      },
      set(value) {
        this.product.productPrice = value.replace(",", ".");
      },
    },

    currentScreenSize() {
      return screenSize()
    }
  },

  watch: {
    phoneNumber(newVal, oldVal) {
      if (newVal !== oldVal) {
        this.cleanPhoneNumber();
      }
    },
  },

  methods: {
    ...mapActions("notifications", ["notify"]),
    ...mapMutations("store", ["setIsLoading"]),

    newAddress(value) {
      this.address = value;
      if (
        isPresent(this.address.street) &&
        isPresent(this.address.city) &&
        isPresent(this.address.country)
      ) {
        this.address.addressError = false;
      }
    },

    cleanPhoneNumber() {
      this.phoneNumber = this.phone.phoneNumber.replace(/\s+/g, "");
    },

    setProductCategory: function (selectedProductCategory) {
      this.product = {
        productName: "",
        productPrice: "",
        productReference: "",
        productCategory: selectedProductCategory,
      };
    },

    resetAttributes: function () {
      this.customer = {
        email: "",
        phoneNumber: "",
        firstName: "",
        lastName: "",
        birthDate: "",
        birthCity: "",
        birthCountry: "",
      };
      this.address = {
        street: "",
        city: "",
        province: "",
        country: "",
        zipCode: "",
        other: "",
        addressError: false,
      };
      this.product = {
        productName: "",
        productPrice: "",
        productReference: "",
        productCategory: this.categories[0],
      };
      this.lead = {
        id: "",
        uniqueToken: "",
        subscriptionLink: "",
        offers: [],
        discount: "",
        invitationSentByEstaly: true,
      };
      this.leadConfiguration = {
        recordASale: false,
        paymentInScreen: false,
        isSaleCreationOpen: false,
        isPaymentOpen: false,
      };
      this.saleRecording = {
        contractDetails: [],
        monthlyPrice: "",
      };
      this.sellerCompleteName = "";
      this.sellerId = "";

      EventBus.emit("resetAddress");
      EventBus.emit("resetProductCategory");
      this.resetForm();
    },

    handleSaleRecord: function () {
      this.setInvitationSentByEstaly(true);
      this.leadConfiguration.recordASale = true;
    },

    sendTextMessage: function () {
      this.setInvitationSentByEstaly(true);
    },

    handlePaymentInScreen: function () {
      this.setInvitationSentByEstaly(false);
      this.leadConfiguration.paymentInScreen = true;
    },

    resetForm: function () {
      this.$refs.leadCreationForm.resetForm();
    },

    handleSelectProduct: function (product) {
      this.product.productName =
        isPresent(product) && product.title.length > 0 ? product.title : "";
    },

    handleSelectSeller: function (seller) {
      this.sellerCompleteName = seller ? seller.name : "";
      this.sellerId = seller ? seller.id : "";
    },

    setInvitationSentByEstaly: function (state) {
      this.lead.invitationSentByEstaly = state;
    },

    closeSaleModal: function (resetAttributes) {
      if (resetAttributes) {
        this.resetAttributes();
      } else {
        this.leadConfiguration.isSaleCreationOpen = false;
      }
    },

    closePaymentModal: function (resetAttributes) {
      if (resetAttributes) {
        this.resetAttributes();
      } else {
        this.leadConfiguration.isPaymentOpen = false;
      }
    },

    handleUpdateOffer({ index, offer }) {
      this.lead.offers = [
        ...this.lead.offers.slice(0, index),
        offer,
        ...this.lead.offers.slice(index + 1),
      ];
    },

    blockEnterBehavior(event) {
      if (event.key === "Enter") {
        event.preventDefault();
        event.stopPropagation();
      }
    },

    submitLeadCreation: async function () {
      if (
        isPresent(this.address.street) &&
        isPresent(this.address.city) &&
        isPresent(this.address.country)
      ) {
        if (this.leadConfiguration.recordASale) {
          await this.handleSaleRecording();
        } else {
          await this.handleLeadCreation();
        }
      } else {
        this.address.addressError = true;
      }
    },

    handleSaleRecording: async function () {
      try {
        this.setIsLoading(true);
        const response = await SaleService.seeContractDetails(
          this.product.productCategory?.value,
          this.product.productPrice,
          this.$store.state.store.selectedStoreId
        );

        const formattedResponse = formatResponse(response.data);
        this.saleRecording.contractDetails = formattedResponse.contracts;
        this.monthlyPrice = (
          parseFloat(this.saleRecording.contractDetails[0].price) / 12
        ).toFixed(2);
      } catch (error) {
        console.log(error.response);
        this.notify({
          category: "simple",
          type: "error",
        });
      } finally {
        this.setIsLoading(false);
      }
      this.leadConfiguration.isSaleCreationOpen = true;
      this.leadConfiguration.recordASale = false;
    },

    handleLeadCreation: async function () {
      const params = {
        lead: {
          customer: {
            first_name: this.customer.firstName,
            last_name: this.customer.lastName,
            email: this.customer.email,
            phone: this.customer.phoneNumber,
            birth_date: this.customer.birthDate,
            birth_city: this.customer.birthCity,
            birth_country: this.customer.birthCountry,
          },
          address: {
            address1: this.address.street,
            address2: this.address.other,
            city: this.address.city,
            province: this.address.province,
            country: this.address.country,
            zip_code: this.address.zipCode,
          },
          product: {
            title: this.product.productName,
            price: this.product.productPrice,
            reference_id: this.product.productReference,
            category: this.product.productCategory?.value,
            currency_code: "EUR",
          },
          invitation_sent_by_estaly: this.lead.invitationSentByEstaly,
          transaction_date: new Date().toJSON().slice(0, 10).replace(/-/g, "/"),
          seller_id: this.sellerId,
          payment_in_store_platform: this.leadConfiguration.paymentInScreen,
        },
      };
      try {
        this.setIsLoading(true);
        const response = await LeadService.create(
          this.$store.state.store.selectedStoreId,
          params
        );
        if (this.leadConfiguration.paymentInScreen) {
          this.lead.id = response.data.lead.id;
          this.lead.offers = response.data.lead.offers;
          this.lead.discount = response.data.lead.discount;
          this.lead.uniqueToken = response.data.lead.estaly_id;
          this.leadConfiguration.isPaymentOpen = true;
          this.leadConfiguration.paymentInScreen = false;
        } else {
          this.notify({
            category: "simple",
            type: "success",
            title:
              "The subscription link was successfully sent to the customer!",
          });
          this.resetAttributes();
        }
      } catch (error) {
        console.log(error.response);
        this.notify({
          category: "simple",
          type: "error",
        });
      } finally {
        this.setIsLoading(false);
      }
    },
  },
};
</script>
