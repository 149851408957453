<template>
  <div class="flex justify-between">
    <DialogTitle as="h1" class="text-gray-900">
      <span class="font-bold">
        {{ $t("Registration of an insurance sale") }}
      </span>
      <div class="mx-1 inline">|</div>
      {{ product.productName }} à {{ formatPrice(product.productPrice) }}
    </DialogTitle>
  </div>
</template>

<script>
import { DialogTitle } from "@headlessui/vue";
import { formatPrice } from "@/utils/price_formatter";

export default {
  name: "InsuranceSaleTitle",

  props: {
    product: {
      type: Object,
      required: true
    }
  },

  components: {
    DialogTitle,
  },

  methods: {
    formatPrice,
  }
}
</script>