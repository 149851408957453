import { isPresent } from "@/utils/validation";

const initialState = {
  activePage: null,
};

export default {
  namespaced: true,

  state: initialState,

  getters: {
    isCurrentActivePage: (state) => (pageName) => {
      return isPresent(state.activePage)
        ? state.activePage["name"] === pageName
        : false;
    },
    isAdminPage: (state) => {
      return isPresent(state.activePage)
        ? state.activePage["to"].startsWith("/admin/")
        : false;
    },
  },

  mutations: {
    setActivePage(state, activePage) {
      state.activePage = activePage;
    },
  },

  actions: {
    updateActivePage({ commit }, activePage) {
      commit("setActivePage", activePage);
    },
    resetActivePage({ commit }) {
      commit("setActivePage", null);
    },
  },
};
