<template>
  <div>
    <h1 class="text-xl py-4 pl-2 font-extrabold tracking-tight text-gray-900">
      {{ $t("Insured products") }}
    </h1>
    <ul
      role="list"
      class="grid grid-cols-1 gap-6 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4"
    >
      <li
        v-for="product in planSale.product"
        v-bind:key="product.id"
        class="bg-white col-span-1 flex flex-col text-center rounded-lg shadow divide-y divide-gray-200"
      >
        <div class="flex-1 flex flex-col p-4">
          <img
            class="w-32 h-32 flex-shrink-0 mx-auto"
            v-bind:src="`${product.imageUrl}`"
            alt=""
          />
          <div class="my-4">
            <h3 class="mt-1 text-gray-900 text-md font-bold">
              {{ product.title }}
            </h3>
          </div>
          <div>
            <div
              v-if="product.itemType"
              class="flex flex-row justify-between text-sm"
            >
              <span>{{ $t("Product Type") }}</span>
              <span class="font-bold">{{ $t(product.itemType) }}</span>
            </div>
            <div class="flex flex-row justify-between text-sm">
              <span>{{ $t("Brand") }}</span>
              <span class="font-bold">{{ product.vendor }}</span>
            </div>
            <div
              v-if="product.model"
              class="flex flex-row justify-between text-sm"
            >
              <span>{{ $t("Model") }}</span>
              <span class="font-bold">{{ product.model }}</span>
            </div>
            <div class="flex flex-row justify-between text-sm">
              <span>{{ $t("Product price") }}</span>
              <span class="font-bold">
                {{ formatPrice(product.price) }}
              </span>
            </div>
            <div
              v-if="product.referenceId"
              class="flex flex-row justify-between text-sm"
            >
              <span>{{ $t("Product reference") }}</span>
              <span class="font-bold truncate">
                {{ product.referenceId }}
              </span>
            </div>
            <div class="flex flex-row justify-between text-sm">
              <span>{{ $t("Purchase date") }}</span>
              <span class="font-bold">{{ product.purchaseDate }}</span>
            </div>
            <div
              v-if="product.insuranceStartDate"
              class="flex flex-row justify-between text-sm"
            >
              <span>{{ $t("Insurance start date") }}</span>
              <span class="font-bold">{{ product.insuranceStartDate }}</span>
            </div>
            <div
              v-if="product.itemImage"
              class="flex flex-row justify-between text-sm"
            >
              <span>{{ $t("Picture") }}</span>
              <a
                class="font-bold underline underline-offset-2"
                :href="product.itemImage"
              >
                {{ $t("Link") }}
              </a>
            </div>
          </div>
        </div>
      </li>
    </ul>
  </div>
</template>

<script>
import { formatPrice } from "@/utils/price_formatter";

export default {
  props: {
    planSale: {
      type: Object,
      required: true,
    },
  },
  methods: {
    formatPrice,
  },
};
</script>
