// https://tailwindcss.com/docs/screens

export function screenSize() {
  return window.innerWidth;
}

export const screens =  {
  sm: 640,
  md: 768,
  lg: 1024,
  xl: 1280,
  '2xl': 1536
};
