<template>
  <div class="flex items-center sm:pt-1.5">
    <Field
      class="h-4 w-4 border-gray-300 rounded text-primary-dark focus:ring-primary"
      :id="id"
      :name="name"
      type="checkbox"
      :disabled="disabled"
      :value="checkedValue"
      :unchecked-value="uncheckedValue"
      v-model="internalValue"
    />
    <label
      :for="id"
      :class="`ml-3 text-sm ${labelClass}`"
    >
      {{ $t(label) }}
    </label>
  </div>
</template>

<script>
import { Field } from "vee-validate";

export default {
  components: {
    Field,
  },
  props: {
    id: {
      type: String,
      required: true,
    },
    label: {
      type: String,
      required: true,
    },
    labelClass: {
      type: String,
      default: "font-semibold"
    },
    name: {
      type: String,
      required: true,
    },
    uncheckedValue: {
      type: [String, Number, Boolean],
      default: false,
    },
    checkedValue: {
      type: [String, Number, Boolean],
      default: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    required: { // TODO: display required field & add validation
      type: Boolean,
      default: false,
    },
    modelValue: {
      type: [String, Number],
      required: true,
    },
    fieldClass: {
      type: String,
      default: "block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-primary sm:text-sm sm:leading-6",
    },
  },
  emits: ["update:modelValue"],
  computed: {
    internalValue: {
      get() {
        return this.modelValue;
      },
      set(value) {
        this.$emit('update:modelValue', value)
      },
    },
  }
};
</script>
