<template>
  <div>
    <label
      v-if="isLabelPresent"
      :for="id"
      :class="`block text-sm leading-6 text-gray-900 sm:pt-1.5 ${labelClass}`"
    >
      {{ $t(label) }}<span v-if="required" class="text-red-600">*</span>
    </label>
    <div class="relative mt-2">
      <Field
        :id="id"
        :name="name"
        :type="type"
        :class="fieldClass"
        :placeholder="placeholder"
        :disabled="disabled"
        v-model="initialValue"
        @input="$emit('update:modelValue', $event.target.value)"
      />
      <div v-if="trailingAddOns" class="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
        <span class="text-gray-500 sm:text-sm">{{ trailingAddOns }}</span>
      </div>
    </div>
  </div>
</template>

<script>
import { Field } from "vee-validate";

export default {
  components: {
    Field,
  },
  data() {
    return {
      initialValue: this.modelValue,
    };
  },
  props: {
    id: {
      type: String,
      required: true,
    },
    label: {
      type: String,
      required: false,
    },
    labelClass: {
      type: String,
      default: "font-semibold"
    },
    name: {
      type: String,
      required: true,
    },
    type: {
      type: String,
      default: "text",
    },
    placeholder: {
      type: String,
      default: "",
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    required: {
      type: Boolean,
      default: false,
    },
    modelValue: {
      type: [String, Number],
      required: true,
    },
    fieldClass: {
      type: String,
      default: "block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-primary sm:text-sm sm:leading-6",
    },
    trailingAddOns: {
      type: String
    },
  },
  emits: ["update:modelValue"],
  computed: {
    isLabelPresent() {
      return !(this.label == null || this.label.length === 0);
    },
  },
  watch: {
    modelValue: function (newVal) {
      this.initialValue = newVal;
    },
  },
};
</script>
