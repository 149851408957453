<template>
  <div>
    <Datepicker
      v-bind:placeholder="this.placeholder"
      class="text-sm w-full rounded-md"
      v-model="date"
      range
      multiCalendars
      v-bind:presetRanges="presetRanges"
      autoApply
      v-bind:enableTimePicker="false"
      v-bind:format="datePickerFormat"
      locale="fr"
      :max-date="new Date()"
    />
  </div>
</template>

<script>
import Datepicker from "@vuepic/vue-datepicker";
import {
  endOfMonth,
  endOfWeek,
  startOfMonth,
  startOfWeek,
  subMonths,
  subWeeks,
  format,
} from "date-fns";
import { 
  currentMonth, 
  previousMonth, 
  currentWeek, 
  previousWeek 
} from "@/utils/date";

export default {
  components: { Datepicker },

  props: ["placeholder"],

  watch: {
    date: function (newValue) {
      if (newValue) {
        const startDate = format(newValue[0], 'yyyy-MM-dd');
        const endDate = format(newValue[1], 'yyyy-MM-dd');
        this.$emit(
          "newDateSelected",
          startDate,
          endDate
        );
      } else {
        this.$emit("newDateSelected", null, null);
      }
    },
  },

  data: () => {
    return {
      date: [],
      presetRanges: [
        {
          id: 1,
          label: "Mois en cours",
          range: [startOfMonth(new Date()), endOfMonth(new Date())],
        },
        {
          id: 2,
          label: "Semaine en cours",
          range: [
            startOfWeek(new Date(), { weekStartsOn: 1 }),
            endOfWeek(new Date(), { weekStartsOn: 1 }),
          ],
        },
        {
          id: 3,
          label: "Mois précédent",
          range: [
            startOfMonth(subMonths(new Date(), 1)),
            endOfMonth(subMonths(new Date(), 1)),
          ],
        },
        {
          id: 4,
          label: "Semaine précédente",
          range: [
            subWeeks(startOfWeek(new Date(), { weekStartsOn: 1 }), 1),
            subWeeks(endOfWeek(new Date(), { weekStartsOn: 1 }), 1),
          ],
        },
        {
          id: 5,
          label: "3 derniers mois",
          range: [new Date().setMonth(new Date().getMonth() - 3), new Date()],
        },
      ],
      comparedTo: {},
    };
  },

  methods: {
    datePickerFormat(date) {
      const startDate = date[0];
      const endDate = date[1];

      const startDay = startDate.getDate();
      const startMonth = startDate.getMonth() + 1;
      const startYear = startDate.getFullYear();

      const endDay = endDate.getDate();
      const endMonth = endDate.getMonth() + 1;
      const endYear = endDate.getFullYear();

      if (startDate && endDate) {
        if (
          startDate instanceof Date &&
          endDate instanceof Date
        ) {
          if (currentMonth(startDate, endDate)) {
            return "Mois en cours";
          }
          if (previousMonth(startDate, endDate)) {
            return "Mois précédent";
          }
          if (currentWeek(startDate, endDate)) {
            return "Semaine en cours";
          }
          if (previousWeek(startDate, endDate)) {
            return "Semaine précédente";
          }
        }
      }

      return `Du ${startDay}/${startMonth}/${startYear} au ${endDay}/${endMonth}/${endYear}`;
    },
  },
};
</script>
