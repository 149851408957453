<style>
    @media (max-width: 1024px) {
        .responsive-table {
            min-width: 1000px !important;
        }
    }
</style>

<template>
  <div class="overflow-auto" style="max-height: 75vh;">
    <TableContainer>
      <SellerStatsTable
        @sortChanged="onSortChanged"
        :totalStats="this.totalStats"
        :stats="this.sellerStats"
      />
    </TableContainer>
  </div>
  <div class="mt-6 text-right">
    <a
      href="#"
      class="relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50"
      v-on:click="handleStoreClick(null)"
    >
      Précédent
    </a>
  </div>
</template>
<script>
import SellerStatsTable from "@/components/analytics/commission/SellerStatsTable.vue";
import TableContainer from "@/components/analytics/retail/utils/TableContainer.vue";

export default {
  components: {
    TableContainer,
    SellerStatsTable,
  },

  props: [
    "sellerStats",
    "totalStats",
  ],

  methods: {
    handleStoreClick(storeId) {
      this.$emit("storeIdSelected", storeId);
    },

    onSortChanged(sortParams) {
      this.$emit("sortChanged", {
        column: sortParams.column,
        order: sortParams.order,
      });
    },
  },
};
</script>

