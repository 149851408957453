<template>
  <div class="flex flex-col gap-10">
    <div class="rounded-md bg-blue-50 p-4">
      <div class="flex">
        <div class="flex-shrink-0">
          <InformationCircleIcon class="h-5 w-5 text-blue-400" aria-hidden="true" />
        </div>
        <div class="ml-3">
          <p class="text-sm text-blue-700 font-body-bold">
            {{ $t("compensation.real_vs_invoiced") }}
          </p>
          <p class="text-xs text-blue-700">
            {{ $t("compensation.real_compensation_info") }}
          </p>
        </div>
      </div>
    </div>

    <div class="bg-white shadow sm:rounded-t-md py-6 px-4 sm:px-6 lg:px-8">
      <div class="flex flex-row justify-between items-center">
        <DailyDateFilterSelector
          class="text-sm w-full sm:w-80 rounded-md"
          :placeholder="$t('Select a time range')"
          @newDateSelected="this.handleNewDateSelected"
        />

        <SearchBarFilter
          :enable-select-all="true"
          filterName="stores"
          :filterIdx="0"
          :available-filters="filters"
          :selected-filters="currentFilters"
          @updateFilterSearch="selectedStoresChanged"
        />
      </div>
    </div>

    <div v-for="section in analyticsSections" :key="section.name">
      <p class="font-body-bold text-2xl mb-2">{{ $t(section.name) }}</p>

      <ul class="grid grid-cols-2 xl:grid-cols-3 gap-4">
        <li
          v-for="(stat, statName) in section.analytics"
          :key="statName"
          class="p-5 bg-white shadow rounded-lg flex flex-col gap-2"
        >
          <div class="flex flex-row gap-2">
            <span class="">{{ $t("analytics." + statName) }}</span>

            <span v-if="$te('analytics_tooltips.' + statName)">
              <NewTooltip :text="$t('analytics_tooltips.' + statName)" text-size="text-md" />
            </span>
          </div>

          <div class="font-body-bold text-2xl sm:text-3xl mx-auto whitespace-nowrap">
            <span class="mr-1">
              {{ stat.value === null ? "-" : formattedValue(stat.value) }}
            </span>

            <span>{{ stat.unit }}</span>
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import DailyDateFilterSelector from "@/components/menu/DailyDateFilterSelector.vue";
import { mapMutations } from "vuex";
import AnalyticsService from "@/api/services/analytics";
import { InformationCircleIcon } from "@heroicons/vue/solid";
import SearchBarFilter from "@/components/menu/SearchBarFilter.vue";
import NewTooltip from "@/components/tooltip/NewTooltip.vue";

export default {
  components: {
    DailyDateFilterSelector,
    InformationCircleIcon,
    SearchBarFilter,
    NewTooltip,
  },

  data() {
    return {
      startDate: null,
      endDate: null,
      analytics: {},
      currentFilters: {
        stores: [],
      },
      filters: {
        stores: [],
      },
      requestTimeout: null,
    };
  },

  mounted() {
    this.fetchAnalytics();
  },

  computed: {
    apiParams() {
      return {
        startDate: this.startDate,
        endDate: this.endDate,
        stores: this.currentFilters.stores,
      };
    },

    newContractsAnalytics() {
      return this.analytics.new_contracts;
    },

    oldContractsAnalytics() {
      return this.analytics.old_contracts;
    },

    allContractsAnalytics() {
      return this.analytics.all_contracts;
    },

    analyticsSections() {
      const sections = [
        {
          name: "All contracts",
          analytics: this.allContractsAnalytics,
        },
        {
          name: "New contracts",
          analytics: this.newContractsAnalytics,
        },
        {
          name: "Active existing contracts",
          analytics: this.oldContractsAnalytics,
        },
      ];
      return sections.filter((section) => section.analytics);
    },
  },

  methods: {
    ...mapMutations("store", ["setIsLoading"]),

    handleNewDateSelected(startDate, endDate) {
      this.startDate = startDate;
      this.endDate = endDate;

      this.fetchAnalytics();
    },

    async fetchAnalytics() {
      try {
        this.setIsLoading(true);
        const response = await AnalyticsService.getGeneralAnalytics(this.apiParams);
        this.analytics = response.data.analytics;
        this.currentFilters.stores = this.analytics.stores;
        this.filters.stores = response.data.available_stores;
      } catch (e) {
        console.error(e);
      } finally {
        this.setIsLoading(false);
      }
    },

    formattedValue(value) {
      return new Intl.NumberFormat("fr-FR").format(value);
    },

    selectedStoresChanged(filterName, filterOptions) {
      this.currentFilters[filterName] = filterOptions;

      // Not fetching the analytics when no store is selected to let the user choose one
      if (filterName === "stores" && filterOptions.length === 0) return;

      this.fetchAnalytics();
    },
  },
};
</script>
