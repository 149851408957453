<template>
  <div class="my-10">
    <listbox
      as="div"
      v-model="selectedReason"
      v-if="correctionTerminationSelected"
    >
      <listbox-label class="text-gray-700">
        {{ $t("Cancellation reason") }}
      </listbox-label>
      <div class="relative mt-2">
        <listbox-button
          class="rounded-lg relative cursor-pointer border bg-white py-2 pl-3 text-left focus:border-primary focus:outline-none focus:ring-1 focus:ring-primary sm:text-sm w-full"
        >
          <div class="items-center">
            <div>
              <span class="font-medium text-primary" v-if="selectedReason">
                {{ $t("Plan cancellation reasons." + selectedReason.reason) }}
              </span>
              <span class="font-medium text-primary" v-else>
                {{ $t("Please select an option in the dropdown below") }}
              </span>
              <span
                class="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2"
              >
                <ChevronDownIcon
                  class="h-5 w-5"
                  style="font-size: 0.5rem"
                  aria-hidden="true"
                />
              </span>
            </div>
          </div>
        </listbox-button>

        <transition
          leave-active-class="transition ease-in duration-100"
          leave-from-class="opacity-100"
          leave-to-class="opacity-0"
        >
          <ListboxOptions
            class="absolute z-10 mt-1 max-h-60 w-full overflow-auto bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm cursor-pointer"
          >
            <ListboxOption
              as="template"
              v-for="reason in reasons"
              :key="reason.id"
              :value="reason"
              v-slot="{ active, selected }"
            >
              <li
                :class="[
                  active
                    ? 'text-button-font-primary bg-primary'
                    : 'text-gray-900',
                  'relative select-none py-2 pl-3 pr-9 cursor-pointer',
                ]"
              >
                <span
                  :class="[
                    selected ? 'font-semibold' : 'font-normal',
                    'block truncate cursor-pointer',
                  ]"
                >
                  {{ $t("Plan cancellation reasons." + reason.reason) }}
                </span>

                <span
                  v-if="selected"
                  :class="[
                    active
                      ? 'text-button-font-primary bg-primary'
                      : 'text-gray-900',
                    'absolute inset-y-0 right-0 flex items-center pr-4 cursor-pointer',
                  ]"
                >
                  <CheckIcon class="h-5 w-5" aria-hidden="true" />
                </span>
              </li>
            </ListboxOption>
          </ListboxOptions>
        </transition>
      </div>
    </listbox>

    <div class="mt-8">
      <div>
        <label for="comment" class="text-gray-700">
          {{ $t("Reason details") }}
        </label>
        <div class="mt-2">
          <textarea
            rows="4"
            name="comment"
            id="comment"
            v-model="feedbackMessage"
            class="block w-full rounded-md border-gray-300 shadow-sm focus:border-primary focus:ring-primary sm:text-sm"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AdminService from "@/api/services/admin";
import {
  Listbox,
  ListboxButton,
  ListboxLabel,
  ListboxOption,
  ListboxOptions,
} from "@headlessui/vue";
import { CheckIcon, ChevronDownIcon } from "@heroicons/vue/outline";

export default {
  components: {
    Listbox,
    ListboxButton,
    ListboxLabel,
    ListboxOption,
    ListboxOptions,
    CheckIcon,
    ChevronDownIcon,
  },

  props: ["termination"],

  emits: ["updateSelectedReason", "updateFeedbackMessage"],

  async created() {
    this.reasons = await this.formattedCancellationReasons();
  },

  data() {
    return {
      reasons: [],
      selectedReason: null,
      feedbackMessage: "",
    };
  },

  watch: {
    selectedReason() {
      this.$emit("updateSelectedReason", this.selectedReason.reason);
    },
    feedbackMessage() {
      this.$emit("updateFeedbackMessage", this.feedbackMessage);
    },
  },

  methods: {
    async formattedCancellationReasons() {
      const reasons = await this.fetchCancellationReasons();

      return reasons.map((reason, i) => {
        return { id: i, reason };
      });
    },

    async fetchCancellationReasons() {
      try {
        return (await AdminService.getPlanSaleCancellationReasons()).data;
      } catch (error) {
        console.error(error);
        return ["I have no particular reason"];
      }
    },
  },

  computed: {
    correctionTerminationSelected() {
      return this.termination !== "correction";
    },
  },
};
</script>
