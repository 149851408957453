<template>
  <div v-if="planSale != null">
    <div class="flex flex-row justify-between">
      <div class="block">
        <h1 class="text-4xl font-heading-1 pl-2 tracking-tight text-gray-900 my-4">
          {{ $t("Plan Sale details") }}
        </h1>
        <p class="mt-1 max-w-2xl text-sm leading-6 text-gray-500">
          {{ $t("Protection details") }}
          <span class="font-bold">{{ planSale.id }}</span>
        </p>
      </div>
      <div v-if="this.planSaleUpdatePermissions" class="flex flex-row gap-2">
        <div v-if="cancellationAvailable">
          <GenericButton @click="openCancelModal = true">
            {{ $t("Cancel") }}
          </GenericButton>
          <CancellationModal
            v-bind:open="openCancelModal"
            @close="openCancelModal = false"
            @cancelPlanSale="cancelPlanSale"
          />
        </div>
        <div v-if="planSaleActive">
          <GenericButton @click="openEditModal = true">
            {{ $t("Update") }}
          </GenericButton>
          <UpdateModal
            :open="openEditModal"
            :planSale="planSale"
            @closeUpdateModal="openEditModal = false"
            @updatePlanSale="updatePlanSale"
          />
        </div>
      </div>
    </div>

    <div class="mt-4">
      <CancellationDetails
        :cancellation="planSale.cancellation"
        v-if="planSale.cancellation"
      />
    </div>

    <div class="mt-4">
      <div class="mt-2 border-t border-gray-100 bg-white px-4 sm:px-6 py-5">
        <h2 class="col-span-2 text-xl font-bold mb-4">{{ $t("Customer") }}</h2>
        <dl class="grid grid-cols-2 gap-x-4 gap-y-6">
          <div>
            <dt class="text-sm font-bold text-gray-500">
              {{ $t("First Name") }} {{ $t("Last Name") }}
            </dt>
            <dd class="mt-1 text-sm text-gray-900">
              {{ planSale.customer.firstName }}
              {{ planSale.customer.lastName }}
            </dd>
          </div>
          <div>
            <dt class="text-sm font-bold text-gray-500">
              {{ $t("Email address") }}
            </dt>
            <dd class="mt-1 text-sm text-gray-900">
              {{ planSale.customer.email }}
            </dd>
          </div>
          <div>
            <dt class="text-sm font-bold text-gray-500">{{ $t("Address") }}</dt>
            <dd class="mt-1 text-sm text-gray-900">
              <p v-if="planSale.customer.address1">
                {{ planSale.customer.address1 }}
              </p>
              <p class="font-bold" v-else>—</p>
            </dd>
          </div>
          <div>
            <dt class="text-sm font-bold text-gray-500">
              {{ $t("Phone number") }}
            </dt>
            <dd class="mt-1 text-sm text-gray-900">
              {{ planSale.customer.phone }}
            </dd>
          </div>
          <div>
            <dt class="text-sm font-bold text-gray-500">
              {{ $t("Birth date") }}
            </dt>
            <dd class="mt-1 text-sm text-gray-900">
              {{ planSale.customer.birthDate }}
            </dd>
          </div>
          <div>
            <dt class="text-sm font-bold text-gray-500">
              {{ $t("Birth city") }}
            </dt>
            <dd class="mt-1 text-sm text-gray-900">
              {{ customerBirthAddress }}
            </dd>
          </div>
          <div v-if="planSale.orderReferenceId">
            <dt class="text-sm font-bold text-gray-500">
              {{ $t("Order ID") }}
            </dt>
            <dd class="mt-1 text-sm text-gray-900">
              {{ planSale.orderReferenceId }}
            </dd>
          </div>
        </dl>
      </div>
    </div>

    <div class="mt-6">
      <div class="mt-2 border-t border-gray-100 bg-white px-4 sm:px-6 py-5">
        <h2 class="col-span-2 text-xl font-bold mb-4">{{ $t("Insurance") }}</h2>
        <dl class="grid grid-cols-2 gap-x-4 gap-y-6">
          <div>
            <dt class="text-sm font-bold text-gray-500">
              {{ $t("Insurance Plan") }}
            </dt>
            <dd class="mt-1 text-sm text-gray-900">
              {{ planSale.planTitle }}
            </dd>
          </div>
          <div>
            <dt class="text-sm font-bold text-gray-500">
              {{ $t("Insurance Offer") }}
            </dt>
            <dd class="mt-1 text-sm text-gray-900">
              {{ planSale.category }}
            </dd>
          </div>
          <div>
            <dt class="text-sm font-bold text-gray-500">
              {{ $t("Warranty Price") }}
            </dt>
            <dd class="mt-1 text-sm text-gray-900">
              {{ formatPrice(planSale.price) }}
            </dd>
          </div>
          <div>
            <dt class="text-sm font-bold text-gray-500">{{ $t("Payment") }}</dt>
            <dd class="mt-1 text-sm text-gray-900">
              {{ planSale.monthlyBilling ? "Mensuel" : "Annuel" }}
            </dd>
          </div>
          <div>
            <dt class="text-sm font-bold text-gray-500">
              {{ $t("Purchase date") }}
            </dt>
            <dd class="mt-1 text-sm text-gray-900">
              {{ planSale.purchaseDate }}
            </dd>
          </div>
          <div>
            <dt class="text-sm font-bold text-gray-500">
              {{ $t("Contract start date") }}
            </dt>
            <dd class="mt-1 text-sm text-gray-900">
              {{ planSale.startDate }}
            </dd>
          </div>
          <div>
            <dt class="text-sm font-bold text-gray-500">
              {{ $t("Subscription form") }}
            </dt>
            <TextCopy
              class="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0 cursor-pointer underline-offset-4 hover:text-primary-dark"
              v-bind:textToCopy="planSale.membershipAgreement"
            >
              {{ $t("Copy link to clipboard") }}
            </TextCopy>
          </div>
          <div>
            <dt class="text-sm font-bold text-gray-500">
              {{ $t("Charged by") }}
            </dt>
            <dd class="mt-1 text-sm text-gray-900">
              {{ planSale.chargedBy }}
            </dd>
          </div>
          <div>
            <dt class="text-sm font-bold text-gray-500">{{ $t("Shop") }}</dt>
            <dd class="mt-1 text-sm text-gray-900">
              {{ planSale.salesChannel }}
            </dd>
          </div>
          <div>
            <dt class="text-sm font-bold text-gray-500">{{ $t("Seller") }}</dt>
            <dd class="mt-1 text-sm text-gray-900">
              <p v-if="sellerName">{{ sellerName }}</p>
              <p class="font-bold" v-else>—</p>
            </dd>
          </div>
        </dl>
      </div>
    </div>

    <ProductsDetails :plan-sale="planSale" />

    <PlanSaleBillingsDetails
      :plan-sale-id="planSaleId"
      :plan-sale-billings="planSale.planSaleBillings"
    />
  </div>
  <div v-else-if="!isLoading" class="text-center flex h-screen">
    <div class="m-auto">
      <p>{{ $t("Something went wrong.") }}</p>
      <GenericButton @onClick="goBackToPlanSalesList" class="mt-4">
        {{ $t("Back") }}
      </GenericButton>
    </div>
  </div>
</template>

<script>
import AdminService from "@/api/services/admin";
import TextCopy from "@/components/utils/TextCopy.vue";
import ProductsDetails from "@/views/admin/plansale/details/ProductsDetails.vue";
import PlanSaleBillingsDetails from "@/views/admin/plansale/details/PlanSaleBillingsDetails.vue";
import { formatPrice } from "@/utils/price_formatter";
import { mapActions, mapMutations, mapGetters } from "vuex";
import GenericButton from "@/components/utils/GenericButton.vue";
import CancellationDetails from "@/views/admin/plansale/details/CancellationDetails.vue";
import UpdateModal from "@/views/admin/plansale/details/UpdateModal.vue";
import CancellationModal from "@/views/admin/plansale/details/CancellationModal.vue";
import { getDefaultErrorMessageContent, getDefaultErrorMessageTitle } from "@/utils/error_messages";

export default {
  components: {
    CancellationModal,
    UpdateModal,
    GenericButton,
    PlanSaleBillingsDetails,
    ProductsDetails,
    TextCopy,
    CancellationDetails,
  },

  data() {
    return {
      planSale: null,
      planSaleId: this.$route.params.id,
      planSaleUpdatePermissions: false,
      openEditModal: false,
      openCancelModal: false,
    };
  },

  async mounted() {
    await this.getPlanSale();
  },

  computed: {
    ...mapGetters("store", ["isLoading"]),

    customerBirthAddress() {
      const birthInformations = [
        this.planSale.customer.birthCity,
        this.planSale.customer.birthCountry,
      ];

      return birthInformations.filter((el) => el).join(", ");
    },

    sellerName() {
      const sellerNameInformations = [
        this.planSale.seller.first_name,
        this.planSale.seller.last_name,
      ];

      return sellerNameInformations.filter((el) => el).join(" ");
    },

    cancellationAvailable: function () {
      return this.planSale.status === "active" && this.planSale.cancellationAvailable;
    },

    planSaleActive() {
      return this.planSale.status === "active";
    },
  },

  methods: {
    ...mapActions("notifications", ["notify"]),
    ...mapMutations("store", ["setIsLoading"]),

    formatPrice,

    async getPlanSale() {
      try {
        this.setIsLoading(true);
        const response = await AdminService.getPlanSale(this.planSaleId);
        this.planSale = response.data;
        this.planSaleUpdatePermissions = this.planSale.planSaleUpdatePermissions;
      } catch (error) {
        console.error(error);
        this.notify({
          category: "simple",
          type: "error",
          title: "Something went wrong.",
        });
      } finally {
        this.setIsLoading(false);
      }
    },

    goBackToPlanSalesList() {
      this.$router.push(`/admin/plan-sales`);
    },

    async cancelPlanSale(reason, message) {
      try {
        this.setIsLoading(true);
        await AdminService.cancelPlanSale(this.planSaleId, {
          plan_sale: {
            cancellation_reason: reason,
            feedback_message: message,
          },
        });
        this.openCancelModal = false;
        this.getPlanSale();
        this.notify({
          category: "simple",
          type: "success",
          text: "Contract cancelled",
        });
      } catch (error) {
        console.error(error);
      } finally {
        this.setIsLoading(false);
      }
    },

    async updatePlanSale(planSaleInfo) {
      try {
        this.setIsLoading(true);
        await AdminService.updatePlanSale(this.planSaleId, {
          plan_sale: planSaleInfo,
        });
        this.openEditModal = false;
        this.getPlanSale();
        this.notify({
          category: "simple",
          type: "success",
          text: "Contract updated",
        });
      } catch (error) {
        const statusCode = error.response.status;
        switch (statusCode) {
          case 422:
            this.notify({
              category: "simple",
              type: "error",
              title: getDefaultErrorMessageTitle(this.$i18n.locale),
              text: error.response.data.error,
            });
            break;
          default:
            this.notify(getDefaultErrorMessageContent(this.$i18n.locale));
        }
      } finally {
        this.setIsLoading(false);
      }
    },
  },
};
</script>
