<template>
  <div class="my-10">
    <div class="mt-4">
      <h2 class="text-xl font-bold">{{ $t("Cancellation") }}</h2>
      <div class="mt-2 border-t border-gray-100">
        <dl class="divide-y divide-gray-100">
          <div
            class="bg-red-50 px-2 py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-3"
          >
            <dt class="text-sm font-medium leading-6 text-gray-900">
              {{ $t("Cancellation date") }}
            </dt>
            <dd
              class="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0"
            >
              {{ cancellation.date }}
            </dd>
          </div>
          <div
            class="bg-red-50 px-2 py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-3"
          >
            <dt class="text-sm font-medium leading-6 text-gray-900">
              {{ $t("Cancellation type") }}
            </dt>
            <dd
              class="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0"
            >
              <span v-if="cancellation.cancellationType">
                {{ $t(`cancellation_types.${cancellation.cancellationType}`) }}
              </span>
            </dd>
          </div>
          <div
            class="bg-red-50 px-2 py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-3"
          >
            <dt class="text-sm font-medium leading-6 text-gray-900">
              {{ $t("Cancellation reason") }}
            </dt>
            <dd
              class="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0"
            >
              <span v-if="cancellation.reason">
                {{ $t(`cancellation_reasons.${cancellation.reason}`) }}
              </span>
            </dd>
          </div>
        </dl>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    cancellation: {
      type: Object,
      required: true,
    },
  },
};
</script>
