<template>
  <div v-if="this.isPresent(this.variant)">
    <TransitionRoot as="template" :show="open">
      <Dialog as="div" class="relative z-50" @close="open = false">
        <TransitionChild
          as="template"
          enter="ease-out duration-300"
          enter-from="opacity-0"
          enter-to="opacity-100"
          leave="ease-in duration-200"
          leave-from="opacity-100"
          leave-to="opacity-0"
        >
          <div
            class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
          />
        </TransitionChild>

        <div class="fixed z-10 inset-0 overflow-y-auto">
          <div
            class="flex items-end sm:items-center justify-center min-h-full p-4 text-center sm:p-0"
          >
            <TransitionChild
              as="template"
              enter="ease-out duration-300"
              enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enter-to="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leave-from="opacity-100 translate-y-0 sm:scale-100"
              leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <DialogPanel
                class="relative bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:max-w-2xl sm:w-full sm:p-6"
              >
                <div
                  class="mx-auto flex items-center justify-center h-12 w-12 rounded-full text-button-font-primary bg-primary"
                >
                  <DocumentReportIcon
                    class="h-6 w-6 text-green-600"
                    aria-hidden="true"
                  />
                </div>

                <VariantContractList v-bind:variant="this.variant" />

                <div class="text-center mt-5">
                  <GenericButton @onClick="close">{{
                    $t("Back")
                  }}</GenericButton>
                </div>
              </DialogPanel>
            </TransitionChild>
          </div>
        </div>
      </Dialog>
    </TransitionRoot>
  </div>
</template>

<script>
import {
  Dialog,
  DialogPanel,
  TransitionChild,
  TransitionRoot,
} from "@headlessui/vue";
import { DocumentReportIcon } from "@heroicons/vue/outline";
import GenericButton from "@/components/utils/GenericButton.vue";
import { isPresent } from "@/utils/validation";
import VariantContractList from "@/views/store/products/details/variant/details/contracts/VariantContractList.vue";
export default {
  components: {
    Dialog,
    DialogPanel,
    TransitionChild,
    TransitionRoot,
    DocumentReportIcon,
    GenericButton,
    VariantContractList,
  },
  props: {
    variant: {
      type: Object,
      required: true,
    },
  },
  computed: {
    open: function () {
      return this.variant !== null;
    },
  },
  methods: {
    close: function () {
      this.$emit("close");
    },
    isPresent: function (variant) {
      return isPresent(variant);
    },
  },
};
</script>
