<template>
  <div class="max-w-7xl mx-auto px-4 sm:px-6 md:px-8">
    <div class="text-center mb-10">
      <GenericButton @onClick="this.redirectToClaimListPage">
        {{ $t("Back") }}
      </GenericButton>
    </div>

    <div v-if="this.doesClaimExists">
      <div class="flex align-center flex-col">
        <h1
          class="text-3xl mb-2 pl-2 font-extrabold tracking-tight text-gray-900 text-center"
        >
          {{ this.currentClaim.friendlyId }}
        </h1>
        <div class="flex justify-center">
          <span
            v-bind:class="[
              this.claimStatusBannerStyle,
              'inline-flex items-center px-3 py-0.5 rounded-full text-sm font-medium',
            ]"
          >
            {{ this.currentClaim.status.text }}
          </span>
        </div>
      </div>

      <RequiredAction
        v-if="this.currentClaim.requiredAction"
        :claim="this.currentClaim"
        :admin-view="this.adminView"
      />

      <ClaimHistoricalChanges
        v-bind:claim="this.currentClaim"
        class="border border-gray-300 mt-10"
      />
      <ClaimCoverageInformation
        v-if="this.doesClaimExists"
        class="mt-10"
        v-bind:claim="this.currentClaim"
      />
      <QuotationInformation class="mt-10" v-bind:claim="this.currentClaim" />
      <InvoiceInformation class="mt-10" v-bind:claim="this.currentClaim" />
      <VoucherInformation
        class="mt-10"
        v-bind:voucher="this.currentClaim.voucher"
      />
      <ClaimInformation class="mt-10" v-bind:claim="this.currentClaim" />
      <ClaimFormResponses class="mt-10" v-bind:claim="this.currentClaim" />
    </div>
    <ClaimNotFound v-else />
  </div>
</template>

<script>
import GenericButton from "@/components/utils/GenericButton.vue";
import ClaimInformation from "@/components/claim/details/information/ClaimInformation.vue";
import ClaimNotFound from "@/components/claim/details/utils/ClaimNotFound.vue";
import ClaimFormResponses from "@/components/claim/details/information/ClaimFormResponses.vue";
import QuotationInformation from "@/components/claim/details/quotation/QuotationInformation.vue";
import ClaimHistoricalChanges from "@/components/claim/details/information/ClaimHistoricalChanges.vue";
import RequiredAction from "@/components/claim/details/RequiredAction.vue";
import { mapActions, mapGetters, mapState } from "vuex";
import { isPresent } from "@/utils/validation";
import InvoiceInformation from "@/components/claim/details/invoice/InvoiceInformation.vue";
import VoucherInformation from "@/components/claim/details/voucher/VoucherInformation.vue";
import ClaimCoverageInformation from "@/components/claim/details/information/ClaimCoverageInformation.vue";

export default {
  components: {
    ClaimCoverageInformation,
    VoucherInformation,
    InvoiceInformation,
    ClaimHistoricalChanges,
    QuotationInformation,
    GenericButton,
    ClaimInformation,
    ClaimNotFound,
    ClaimFormResponses,
    RequiredAction,
  },

  props: ["adminView"],

  beforeMount() {
    const claimFriendlyId = this.$route.params.id;
    if (this.adminView) {
      this.getAdminClaim({ claimFriendlyId: claimFriendlyId });
    } else {
      this.getStoreClaim({
        storeId: this.$store.state.store.selectedStoreId,
        claimFriendlyId: claimFriendlyId,
      });
    }
  },

  computed: {
    ...mapGetters("claims", ["answers", "doesClaimExists"]),
    ...mapState("claims", ["currentClaim"]),
    ...mapState("store", ["selectedStoreId"]),

    claimStatusBannerStyle: function () {
      const color =
        isPresent(this.currentClaim) &&
        isPresent(this.currentClaim.status)
          ? this.currentClaim.status.color
          : "yellow";
      return `bg-${color}-100 text-${color}-800`;
    },
  },

  methods: {
    ...mapActions("claims", ["getStoreClaim", "getAdminClaim", "reset"]),
    ...mapGetters("claims", ["getClaimFriendlyId"]),

    redirectToClaimListPage: function () {
      this.$router.back();
    },
  },
};
</script>
