import { createRouter, createWebHistory } from "vue-router";
import Signin from "../views/public/Signin.vue";
import Signup from "../views/public/Signup.vue";
import ForgotPassword from "../views/public/ForgotPassword.vue";
import Profile from "../views/common/Profile.vue";

import PasswordReset from "../views/public/PasswordReset.vue";
import PlanDetails from "../views/public/PlanDetails.vue";
import GetStarted from "../views/public/GetStarted.vue";

import store from "../store";
import StoreClaimDetails from "@/views/store/claim/details/StoreClaimDetails";

import HomeAdmin from "../views/admin/analytics/Analytics.vue";
import AdminPlanSales from "../views/admin/plansale/AdminPlanSales.vue";
import Invoices from "../views/admin/invoice/Invoices.vue";
import AdminClaimList from "@/views/admin/claim/list/AdminClaimList";
import AdminClaimDetails from "@/views/admin/claim/details/AdminClaimDetails";

import HomePos from "../views/pos/home/HomePos.vue";
import ProductList from "@/views/store/products/list/ProductList.vue";
import ProductDetails from "@/views/store/products/details/ProductDetails.vue";
import StoreClaimList from "@/views/store/claim/list/StoreClaimList.vue";
import PosClaimDetails from "@/views/pos/claim/details/PosClaimDetails.vue";
import PosClaimList from "@/views/pos/claim/list/PosClaimList.vue";
import PosPlanSales from "@/views/pos/plansale/PosPlanSales.vue";
import Onboarding from "@/views/public/Onboarding.vue";
import Analytics from "@/views/pos/analytics/Analytics.vue";
import PosSellerList from "@/views/pos/seller/PosSellerList.vue";
import StorePlanSales from "@/views/store/plansale/StorePlanSales.vue";
import PlanSaleDetails from "@/views/admin/plansale/PlanSaleDetails.vue";
import StoreList from "../views/admin/store/StoreList.vue";
import StoreDetails from "../views/admin/store/StoreDetails.vue";
import UserList from "../views/admin/user/UserList.vue";

const publicRoutes = [
  {
    path: "/signin",
    component: Signin,
  },

  {
    path: "/signup",
    component: Signup,
  },

  {
    path: "/forgot-password",
    component: ForgotPassword,
  },

  {
    path: "/password-reset/:id",
    component: PasswordReset,
  },

  {
    path: "/plan-details/:contractSku",
    component: PlanDetails,
  },

  {
    path: "/onboarding/start",
    component: GetStarted,
  },
];

const adminRoutes = [
  {
    path: "/admin",
    component: HomeAdmin,
    meta: {
      layout: "admin-layout",
      navigation: "Management",
      permission: "admin",
    },
  },
  {
    path: "/admin/plan-sales",
    component: AdminPlanSales,
    meta: {
      layout: "admin-layout",
      navigation: "Plan sales",
      permission: "plan_sales",
    },
  },
  {
    path: "/admin/plan-sales/:id",
    component: PlanSaleDetails,
    meta: {
      layout: "admin-layout",
      navigation: "Plan sales",
      permission: "plan_sales",
    },
  },
  {
    path: "/admin/claim",
    component: AdminClaimList,
    meta: {
      layout: "admin-layout",
      navigation: "Claim List",
      permission: "claims",
    },
  },
  {
    path: "/admin/claim/:id",
    component: AdminClaimDetails,
    meta: {
      layout: "admin-layout",
      navigation: "Claim List",
      permission: "claims",
    },
  },
  {
    path: "/admin/profile",
    component: Profile,
    meta: { layout: "admin-layout" },
  },
  {
    path: "/admin/invoices",
    component: Invoices,
    meta: {
      layout: "admin-layout",
      navigation: "Invoices",
      permission: "invoices",
    },
  },
  {
    path: "/admin/stores",
    component: StoreList,
    meta: {
      layout: "admin-layout",
      navigation: "Stores",
      permission: "stores",
    },
  },
  {
    path: "/admin/stores/:id",
    component: StoreDetails,
    meta: {
      layout: "admin-layout",
      navigation: "Stores",
      permission: "stores",
    },
  },
  {
    path: "/admin/users",
    component: UserList,
    meta: {
      layout: "admin-layout",
      navigation: "Users",
      permission: "users",
    },
  },
];

const platformRoutes = [
  {
    path: "/platform/plan_sales",
    component: StorePlanSales,
    meta: { layout: "platform-layout", navigation: "Plan sales" },
  },
  {
    path: "/platform/products/:id",
    component: ProductDetails,
    meta: { layout: "platform-layout", navigation: "Products" },
  },
  {
    path: "/platform/products",
    component: ProductList,
    meta: { layout: "platform-layout", navigation: "Products" },
  },
  {
    path: "/platform/claim",
    component: StoreClaimList,
    meta: {
      layout: "platform-layout",
      navigation: "Claim List",
      permission: "claims",
    },
  },
  {
    path: "/platform/claim/:id",
    component: StoreClaimDetails,
    meta: {
      layout: "platform-layout",
      navigation: "Claim List",
      permission: "claims",
    },
  },
  {
    path: "/platform/profile",
    component: Profile,
    meta: { layout: "platform-layout" },
  },
];

const posRoutes = [
  {
    path: "/pos",
    component: HomePos,
    meta: { layout: "pos-layout", navigation: "Subscription" },
  },
  {
    path: "/pos/plan-sales",
    component: PosPlanSales,
    meta: {
      layout: "pos-layout",
      navigation: "Plan sales",
      permission: "plan_sales",
    },
  },
  {
    path: "/pos/claims",
    component: PosClaimList,
    meta: {
      layout: "pos-layout",
      navigation: "Claim List",
      permission: "claims",
    },
  },
  {
    path: "/pos/claims/:id",
    component: PosClaimDetails,
    meta: {
      layout: "pos-layout",
      navigation: "Claim List",
      permission: "claims",
    },
  },
  {
    path: "/pos/analytics",
    component: Analytics,
    meta: {
      layout: "pos-layout",
      navigation: "Performances",
      permission: "plan_sales",
    },
  },
  {
    path: "/pos/profile",
    component: Profile,
    meta: { layout: "pos-layout" },
  },
  {
    name: "PosSellers",
    path: "/pos/sellers",
    component: PosSellerList,
    meta: {
      layout: "pos-layout",
      navigation: "Sellers",
    },
  },
];

const storeRoutes = [
  {
    path: "/onboarding",
    component: Onboarding,
  },
];

const routes = publicRoutes.concat(
  storeRoutes,
  posRoutes,
  platformRoutes,
  adminRoutes
);

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

function needsHttpsRedirection() {
  return (
    (process.env.NODE_ENV === "production" ||
      process.env.NODE_ENV === "demo" ||
      process.env.NODE_ENV === "staging") &&
    location.protocol !== "https:"
  );
}

function redirectHttps() {
  location.replace(
    `https:${location.href.substring(location.protocol.length)}`
  );
}

function redirectToDefaultRoute(next) {
  if (store.getters["auth/isAdmin"]) {
    next("/admin");
  } else if (store.getters["auth/isSeller"]) {
    next("/pos/plan-sales");
  } else {
    next("/signin");
  }
}

function hasPermission(to, userPermissions) {
  return to.meta.permission
    ? userPermissions.includes(to.meta.permission)
    : true;
}

router.beforeEach((to, from, next) => {
  if (needsHttpsRedirection()) {
    redirectHttps();
  } else {
    const publicPages = publicRoutes.map((route) => route.path);
    const authRequired = !to.matched.some((matched) =>
      publicPages.includes(matched.path)
    );
    const signedIn = store.state.auth.signedIn;

    if (!authRequired) {
      // Public routes
      next();
      return;
    }

    if (!signedIn || store.state.auth.currentUser.permissions === undefined) {
      // Signin
      next("/signin");
    } else {
      if (to.path === "/") {
        // Default route
        redirectToDefaultRoute(next);
      } else {
        if (!hasPermission(to, store.state.auth.currentUser.permissions)) {
          next(from.path);
        } else {
          next();
        }
      }
    }
  }
});

router;

export default router;
