<template>
  <div>
    <ClaimListTitle />
    <SearchBar
      v-bind:title="$t('Claim filters')"
      v-bind:placeholder="$t('SearchClaims')"
      v-bind:current-page="currentPage"
      v-bind:available-filters="availableFilters"
      v-bind:selected-filters="selectedFilters"
      v-bind:initial-keywords="keywords"
      @updateFilterSearch="updateFilterSearch"
      @updateKeywordSearch="updateKeywordSearch"
      class="my-4"
    />
    <PaginatedClaimList
      v-bind:total-number-of-claims="totalNumberOfClaims"
      v-bind:current-page="currentPage"
      v-bind:claims="claims"
      @changePage="handleChangePage"
      @redirectToClaimDetailsPage="redirectToClaimDetailsPage"
    />
  </div>
</template>

<script>
import PaginatedClaimList from "@/components/claim/list/PaginatedClaimList.vue";
import { mapState, mapActions, mapGetters } from "vuex";
import SearchBar from "@/components/menu/SearchBar.vue";
import ClaimListTitle from "@/components/claim/list/ClaimListTitle.vue";

export default {
  components: {
    ClaimListTitle,
    SearchBar,
    PaginatedClaimList,
  },

  data() {
    return {
      currentPage: 1
    };
  },

  computed: {
    ...mapGetters("claim_list", ["getClaims"]),
    ...mapState("claim_list", [
      "claims",
      "totalNumberOfClaims",
      "availableFilters",
      "selectedFilters",
      "keywords",
    ]),
  },

  methods: {
    ...mapActions("claim_list", [
      "fetchClaims",
      "updateFilter",
      "updateSearch",
    ]),

    handleChangePage: function (page) {
      this.updatePage(page);
    },

    redirectToClaimDetailsPage: function (claimId) {
      this.$router.push(`/admin/claim/${claimId}`);
    },

    updateFilterSearch: function (filterName, filterOptions) {
      this.updatePage(1, false);
      this.updateFilter({
        filterName: filterName,
        filterOptions: filterOptions,
        page: this.currentPage,
      });
    },

    updateKeywordSearch: function (keyword) {
      this.updatePage(1, false);
      this.updateSearch({
        searchString: keyword,
        page: this.currentPage,
      });
    },

    updatePage: function (newPage, refreshClaims = true) {
      this.currentPage = newPage;
      history.pushState({}, "", `?page=${newPage}`);
      if (refreshClaims) {
        this.fetchClaims({ page: this.currentPage });
      }
    },
  },

  beforeMount() {
    this.currentPage = parseInt(this.$route.query.page) || 1;
    this.fetchClaims({ page: this.currentPage });
  },
};
</script>
