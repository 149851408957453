<template>
  <Listbox as="div" v-model="selectedStore" class="flex items-center">
    <ListboxLabel class="block text-sm font-medium text-white"></ListboxLabel>
    <div class="relative">
      <ListboxButton
        class="rounded-lg relative cursor-pointer border bg-white py-2 pl-3 text-left focus:border-primary focus:outline-none focus:ring-1 focus:ring-primary sm:text-sm w-52"
      >
        <div class="flex flex-row items-center">
          <UserCircleIcon class="h-6 w-6 text-primary-dark" />
          <div class="ml-2 block truncate">
            <span
              class="block truncate font-medium text-primary-dark"
              style="font-size: 0.5rem"
            >
              Compte actif
            </span>
            <span class="block truncate font-medium text-xs pr-6 text-primary-dark">
              {{ selectedStore.salesChannel }}
            </span>
            <span
              class="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-1"
            >
              <ChevronDownIcon
                class="h-5 w-5"
                style="font-size: 0.5rem"
                aria-hidden="true"
              />
            </span>
          </div>
        </div>
      </ListboxButton>

      <transition
        leave-active-class="transition ease-in duration-100"
        leave-from-class="opacity-100"
        leave-to-class="opacity-0"
      >
        <ListboxOptions
          class="absolute z-10 mt-1 max-h-60 w-full overflow-auto bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm cursor-pointer"
        >
          <ListboxOption
            as="template"
            v-for="store in storesOptions"
            :key="store.id"
            :value="store"
            v-slot="{ active, selected }"
          >
            <li
              :class="[
                active ? 'text-primary-dark bg-primary-light' : 'text-primary-dark',
                'relative cursor-default select-none py-2 pl-3 pr-9 cursor-pointer',
              ]"
            >
              <span
                :class="[
                  selected ? 'font-semibold' : 'font-normal',
                  'text-xs block truncate cursor-pointer',
                ]"
                >
                  {{ store.salesChannel }}
                </span>
              <span
                v-if="selected"
                :class="[
                  active ? 'text-primary-dark' : 'text-primary-dark',
                  'absolute inset-y-0 right-0 flex items-center pr-4 cursor-pointer',
                ]"
              >
                <CheckIcon class="h-5 w-5" aria-hidden="true" />
              </span>
            </li>
          </ListboxOption>
        </ListboxOptions>
      </transition>
    </div>
  </Listbox>
</template>

<script>
import { mapActions } from "vuex";
import { CheckIcon, ChevronDownIcon, UserCircleIcon, } from "@heroicons/vue/solid";
import {
  Listbox,
  ListboxButton,
  ListboxLabel,
  ListboxOption,
  ListboxOptions,
} from "@headlessui/vue";
import StoreService from "@/api/services/store";
import { isPresent } from "@/utils/validation";

export default {
  components: {
    Listbox,
    ListboxButton,
    ListboxLabel,
    ListboxOption,
    ListboxOptions,
    CheckIcon,
    ChevronDownIcon,
    UserCircleIcon,
  },

  data() {
    return {
      stores: []
    };
  },

  computed: {
    storesOptions() {
      return [{ id: 0, salesChannel: "Admin" }, ...this.stores];
    },

    selectedStoreId() {
      return this.$store.state.store.selectedStoreId;
    },

    selectedStore: {
      get() {
        if (!this.selectedStoreId) return this.storesOptions[0];

        return this.stores.find((x) => x.id === this.selectedStoreId) || this.storesOptions[0];
      },

      set(value) {
        if (!isPresent(value)) return this.updateStoreId("");

        if (value.salesChannel === "Admin") {
          this.updateStoreId(value.id);
          this.$router.push("/admin");
        } else if (value.source === "offline") {
          this.updateStoreId(value.id);
          this.$router.push(`/pos`);
        } else {
          this.updateStoreId(value.id);
          this.$router.push(`/platform/products`);
        }
      }
    }
  },

  methods: {
    ...mapActions("store", ["updateStoreId"]),

    async getStores() {
      try {
        const { data: { stores } } = await StoreService.getStores({ status: "active" });
        this.stores = stores;
      } catch (error) {
        console.error(error.response);
        this.notify({
          category: "simple",
          type: "error",
          title: "Une erreur s'est produite - rechargez la page",
          text: error.response?.data?.error || error.message,
        });
      }
    },
  },

  beforeMount() {
    this.getStores();
  },
};
</script>
