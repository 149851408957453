<template>
  <div>
    <div>
      <FormField
        id="email"
        label="Adresse email du magasin"
        labelClass="font-normal"
        name="email"
        placeholder="abc@store.com"
        required
        v-model="formData.email"
      />
      <hr class="my-4">
      <h3 class="font-bold">Coordonnées du référent</h3>
      <p class="text-sm">
        Le référent correspond au responsable magasin, ces informations sont importantes pour le planning et le suivi des formations.
      </p>
      <Checkbox
        class="mt-1"
        id="current_profile_is_referent"
        name="current_profile_is_referent"
        label="Je suis le référent"
        labelClass="font-normal"
        v-model="formData.currentUserIsReferent"
      />
      <FormField
        id="referent_last_name"
        label="Nom du référent"
        labelClass="font-normal"
        name="referent_last_name"
        required
        v-model="formData.referent.lastName"
      />
      <FormField
        id="referent_first_name"
        label="Prénom du référent"
        labelClass="font-normal"
        name="referent_first_name"
        required
        v-model="formData.referent.firstName"
      />
      <FormField
        id="referent_email"
        label="Email du référent"
        labelClass="font-normal"
        name="referent_email"
        placeholder="Email du référent"
        required
        v-model="formData.referent.email"
      />
      <FormField
        id="referent_phone"
        label="Numéro de téléphone du référent"
        labelClass="font-normal"
        name="referent_phone"
        placeholder="06 12 34 56 78"
        required
        v-model="formData.referent.phone"
      />
      <Selector
        id="referent_role"
        label="Fonction du référent"
        labelClass="font-normal"
        name="referent_role"
        required
        v-model="formData.referentRole"
        :options="referentRoles"
      />
    </div>
    <div class="mt-4 flex justify-end">
      <Loading v-if="isLoading"/>
      <GenericButton v-else @click="updateContactInformations">
        Valider
      </GenericButton>
    </div>
  </div>
</template>

<script>
import GenericButton from "@/components/utils/GenericButton.vue";
import Selector from "@/components/utils/Selector.vue";
import Loading from "@/components/utils/Loading.vue";
import FormField  from "@/components/utils/FormField.vue";
import Checkbox  from "@/components/utils/Checkbox.vue";
import StoreService from "@/api/services/store";
import { mapActions, mapState } from "vuex";

export default {
  props: ["store"],

  components: {
    GenericButton,
    Selector,
    Checkbox,
    FormField,
    Loading
  },

  data() {
    return {
      isLoading: false,
      formData: {
        email: "",
        currentUserIsReferent: false,
        referent: {
          lastName: "",
          firstName: "",
          email: "",
          phone: "",
        },
        referentRole: "",
      },
      referentRoles: [],
    };
  },

  created() {
    this.setupFormData();
  },

  emits: ['configurationUpdated'],

  computed: {
    ...mapState("auth", ["currentUser"]),
  },

  methods: {
    ...mapActions("notifications", ["notify"]),
    ...mapActions("auth", ["refreshUser"]),
    async setupFormData() {
      this.isLoading = true;
      try {
        const { data: { store, referent_roles } } = await StoreService.getStoreContactInformations(this.store.id);
        this.formData.email = store.email;
        this.formData.currentUserIsReferent = store.current_profile_is_referent;
        this.formData.referent.lastName = store.referent.last_name;
        this.formData.referent.firstName = store.referent.first_name;
        this.formData.referent.email = store.referent.email;
        this.formData.referent.phone = store.referent.phone;
        this.formData.referentRole = store.referent_role;
        this.referentRoles = referent_roles;
      } catch (error) {
        this.notify({
          category: "simple",
          type: "error",
          title: "Une erreur s'est produite lors du chargement du formulaire",
          text: error.response?.data?.error || error.message,
        });
      } finally {
        this.isLoading = false;
      }
    },
    async updateContactInformations() {
      const storeId = this.store.id;
      try {
        await StoreService.updateStoreContactInformation(storeId, {
          store: {
            email: this.formData.email,
            current_profile_is_referent: this.formData.currentUserIsReferent,
            referent_role: this.formData.referentRole,
            referent: {
              last_name: this.formData.referent.lastName,
              first_name: this.formData.referent.firstName,
              email: this.formData.referent.email,
              phone: this.formData.referent.phone,
            }
          }
        });
        if (this.formData.currentUserIsReferent) this.refreshUser(); // currentUser profile was updated, so refresh data
        this.$emit("configurationUpdated");
        this.notify({
          category: "simple",
          type: "success",
          title: "Informations mises à jour",
          text: "Les coordonées du magasin ont été mises à jour avec succès",
        });
      } catch (error) {
        this.notify({
          category: "simple",
          type: "error",
          title: "Une erreur s'est produite",
          text: error.response?.data?.error || error.message,
        });
      }
    },
  },
  watch: {
    "formData.currentUserIsReferent"(newValue) {
      if (newValue === true) {
        this.formData.referent.email = this.currentUser.email;
        this.formData.referent.firstName = this.currentUser.firstName;
        this.formData.referent.lastName = this.currentUser.lastName;
      } else {
        this.formData.referent.email = "";
        this.formData.referent.firstName = "";
        this.formData.referent.lastName = "";
      }
    },
  },
};
</script>
