<template>
  <Modal v-bind:open="this.open" @close="this.close" class="md:max-w-4xl">
    <div
      v-if="challenge && sellers"
      class="font-extralight flex flex-col text-primary-dark justify-center space-y-4"
    >
      <div class="overflow-x-auto">
        <table class="min-w-full text-sm divide-y divide-gray-200">
          <!-- En-têtes du tableau -->
          <thead class="bg-gray-50">
            <tr>
              <th
                scope="col"
                class="px-6 py-3 text-left font-medium text-gray-500 uppercase tracking-wider"
              >
                Vendeur
              </th>
              <th
                scope="col"
                class="px-6 py-3 text-left font-medium text-gray-500 uppercase tracking-wider"
              >
                Nombre de contrats
              </th>
              <th
                scope="col"
                class="px-6 py-3 text-left font-medium text-gray-500 uppercase tracking-wider"
              >
                Gain CC
              </th>
            </tr>
          </thead>
          <!-- Corps du tableau -->
          <tbody class="bg-white divide-y divide-gray-200">
            <tr v-for="(seller, index) in sellers" :key="index">
              <td class="px-6 py-4 whitespace-nowrap">{{ seller.name }}</td>
              <td class="px-6 py-4 whitespace-nowrap">
                {{ seller.planSalesCount }}
              </td>
              <td class="px-6 py-4 whitespace-nowrap">
                {{ formatPrice(seller.rewardAmount) }}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </Modal>
</template>

<script>
import Modal from "@/components/modal/Modal.vue";
import { mapActions } from "vuex";
import { formatPrice } from "@/utils/price_formatter";

export default {
  components: {
    Modal,
  },
  props: ["open", "challenge", "sellers"],

  computed: {},

  methods: {
    formatPrice,
    ...mapActions("notifications", ["notify"]),

    close() {
      this.$emit("close");
    },
  },
};
</script>
