<template>
  <Modal v-bind:open="this.open" @close="this.close" class="md:max-w-4xl">
    <div
      v-if="challenge && sellers"
      class="font-extralight flex flex-col justify-center space-y-4"
    >
      <p class="font-heading-1 text-primary-dark text-4xl text-center">
        🚀 Nouveau challenge en cours 🚀
      </p>
      <p>
        Nous sommes heureux de lancer un challenge pour dynamiser les ventes
        d'assurances.
      </p>
      <p>
        Le challenge s'étend du
        <span class="font-bold">{{ challenge.startDate }}</span> au
        <span class="font-bold">{{ challenge.endDate }}</span
        >.
      </p>
      <p>
        Atteignez un palier de ventes et gagnez des récompenses. Plus vous
        vendez, plus vous gagnez!
      </p>

      <div v-for="seller in sellers" :key="seller">
        <div>
          <p class="text-xs font-bold">{{ seller.name }}</p>
        </div>
        <div class="w-full mt-4 flex flex-col pb-6 justify-self-center pr-10">
          <div class="relative h-2 bg-gray-200 rounded overflow-visible">
            <div
              class="absolute left-0 top-0 h-2 bg-primary-dark rounded"
              style="top: 50%; transform: translateY(-50%)"
              :style="{ width: progressWidth(seller) + '%' }"
            ></div>

            <div
              v-for="target in challenge.salesTargets"
              :key="target.target"
              class="bg-primary rounded-full text-white absolute flex items-center justify-center h-6 w-6"
              :style="{
                left: `calc(${targetPosition(target.target)}% - 0.5rem)`,
                top: '50%',
                transform: 'translateY(-50%)',
              }"
            >
              <div
                class="text-xs"
                :class="{
                  'text-yellow-400 font-bold':
                    target.target === nextTarget(seller).target,
                }"
              >
                {{ target.target }}
              </div>
            </div>

            <div
              v-for="target in challenge.salesTargets"
              :key="target.reward"
              class="rounded-full text-primary-dark font-bold absolute flex items-center justify-center h-6 w-6"
              :style="{
                left: `calc(${targetPosition(target.target)}% - 0.3rem + 20px)`, // Décalage à droite
                top: 'calc(50% + 20px)', // Décalage vers le bas
                transform: 'translate(-50%, -50%)',
              }"
            >
              <div
                class="text-xs"
                :class="{
                  'text-yellow-400 font-extrabold ml-8':
                    target.target === nextTarget(seller).target,
                }"
              >
                {{ formatPrice(target.reward) }}
              </div>
            </div>

            <div
              class="bg-white rounded-full text-primary-dark absolute flex items-center justify-center h-6 w-6 shadow-md"
              :style="{
                left: `calc(${progressWidth(seller)}% - 0.5rem)`,
                bottom: '120%',
                transform: 'translateY(0.5rem)',
              }"
            >
              <div class="text-xs">{{ seller.planSalesCount }}</div>
            </div>
          </div>
        </div>
      </div>
      <p>
        Vos récompenses seront versées sous forme de cartes 🎁 (multi-enseignes,
        utilisable en ligne et en magasin) à débloquer à la fin du challenge.
      </p>
      <p>
        Alors, visez le palier le plus haut possible 🎯 et récoltez votre
        récompense 🏆!
      </p>
      <p>Bonne chance 😉</p>
    </div>
  </Modal>
</template>

<script>
import Modal from "@/components/modal/Modal.vue";
import { formatPrice } from "@/utils/price_formatter";
import { mapActions } from "vuex";

export default {
  components: {
    Modal,
  },
  props: ["open", "challenge", "sellers"],

  computed: {},

  methods: {
    ...mapActions("notifications", ["notify"]),
    formatPrice,

    close() {
      this.$emit("close");
    },

    targetPosition(target) {
      return (target / this.maxTargetChallenge()) * 100;
    },

    maxTarget() {
      return Math.max(
        ...this.challenge.salesTargets.map((item) => item.target)
      );
    },

    nextTarget(seller) {
      const salesTargets = this.challenge?.salesTargets || [];
      const planSalesCount = seller?.planSalesCount || 0;

      return (
        salesTargets.find((t) => t.target > planSalesCount) ||
        salesTargets[salesTargets.length - 1]
      );
    },
    maxTargetChallenge() {
      const salesTargets = this.challenge?.salesTargets || [];

      return salesTargets[salesTargets.length - 1]?.target;
    },
    progressWidth(seller) {
      return this.maxTargetChallenge
        ? (seller?.planSalesCount / this.maxTargetChallenge()) * 100
        : 100;
    },
  },
};
</script>
