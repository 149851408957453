<template>
  <CollapsableContent 
    :title="isOfflineStore ? $t('Offers and incentives') : $t('Proposed offers')" 
    :open="false"
    :subtitle="isOfflineStore ? 'Incentive vendeur : ' + (sellerIncentivePercentage * 100) + '%' : ''">
    <template #header>
      <GenericButton v-if="isOfflineStore" @click="openIncentiveModal">
        {{ $t("Configure sellers incentive") }}
      </GenericButton>
    </template>
    <div>
      <div class="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
        <table class="min-w-full divide-y divide-gray-300">
          <thead class="bg-gray-50">
            <tr class="hidden md:table-row">
              <th
                scope="col"
                class="py-3 pl-4 pr-3 text-left text-xs font-medium uppercase tracking-wide text-gray-500"
              >
                {{ $t("Title") }}
              </th>
              <th
                scope="col"
                class="text-center py-3 pl-4 pr-3 text-left text-xs font-medium uppercase tracking-wide text-gray-500"
              >
                {{ $t("Price range") }}
              </th>
              <th
                scope="col"
                class="text-center py-3 pl-4 pr-3 text-left text-xs font-medium uppercase tracking-wide text-gray-500"
              >
                {{ $t("Price incl. VAT") }}
              </th>
              <th
                scope="col"
                class="text-center py-3 pl-4 pr-3 text-left text-xs font-medium uppercase tracking-wide text-gray-500"
              >
                {{ $t("Merchant's commission") }}
              </th>
              <th
                v-if="isOfflineStore"
                scope="col"
                class="text-center py-3 pl-4 pr-3 text-left text-xs font-medium uppercase tracking-wide text-gray-500"
              >
                {{ $t("Annualized merchant's commission") }}
              </th>
              <th
                v-if="isOfflineStore"
                scope="col"
                class="text-center py-3 pl-4 pr-3 text-left text-xs font-medium uppercase tracking-wide text-gray-500"
              >
                {{ $t("Incentive amount") }}
              </th>
            </tr>
          </thead>
          <tbody class="divide-y divide-gray-200 bg-white text-gray-900 text-xs font-medium">
            <tr
              v-for="matchedContract in this.matchedContracts"
              v-bind:key="matchedContract.id"
            >
              <td
                class="whitespace-nowrap py-4 pl-4 pr-2 sm:pl-4"
              >
                {{ matchedContract.title }}
              </td>
              <td
                class="text-center whitespace-nowrap py-4 pl-4 pr-2 sm:pl-4"
              >
                {{ matchedContract.priceBandMin }} à {{ matchedContract.priceBandMax }}
              </td>
              <td
                class="text-center whitespace-nowrap py-4 pl-4 pr-2 sm:pl-4"
              >
                {{ matchedContract.price }}
              </td>
              <td
                class="text-center whitespace-nowrap py-4 pl-4 pr-2 sm:pl-4"
              >
                {{ matchedContract.theoricalStoreCommissionAmount }}
              </td>
              <td
                v-if="isOfflineStore"
                class="text-center whitespace-nowrap py-4 pl-4 pr-2 sm:pl-4"
              >
                {{ matchedContract.annualizedStoreCommissionAmount }}
              </td>
              <td
                v-if="isOfflineStore"
                class="text-center whitespace-nowrap py-4 pl-4 pr-2 sm:pl-4"
              >
                {{ matchedContract.sellerIncentive }}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </CollapsableContent>
  <IncentiveModal 
    :openModal="isIncentiveModalOpen"
    :sellerIncentivePercentage="sellerIncentivePercentage"
    @closeIncentiveModal="closeIncentiveModal"
    @updateIncentive="updateSellerIncentivePercentage"
  />
</template>

<script>
import GenericButton from "@/components/utils/GenericButton.vue";
import IncentiveModal from "@/views/admin/store/details/active/IncentiveModal.vue";
import CollapsableContent from "@/components/utils/CollapsableContent.vue";
import StoreService from "@/api/services/store";
import { mapActions } from "vuex";

export default {
  components: {
    GenericButton,
    IncentiveModal,
    CollapsableContent,
  },
  props: ["matchedContracts", "storeId", "sellerIncentivePercentage", "isOfflineStore"],
  data() {
    return {
      isIncentiveModalOpen: false,
    };
  },
  methods: {
    ...mapActions("notifications", ["notify"]),
  
    openIncentiveModal() {
      this.isIncentiveModalOpen = true;
    },

    closeIncentiveModal() {
      this.isIncentiveModalOpen = false;
    },

    async updateSellerIncentivePercentage(incentive) {
      try {
        const data = {
            seller_incentive_percentage: incentive,
        }
        const response = await StoreService.updateStore(this.storeId, data);
        this.sellers = response.data.sellers;
        await this.notify({
          category: "simple",
          type: "success",
          text: "L'incentive vendeur a été configurée."
        })
        this.$emit("reloadStore");
        this.closeIncentiveModal();
      } catch (error) {
        const errorMessage = error.response?.data?.errors?.[0] || error.message;

        await this.notify({
          category: "simple",
          type: "error",
          text: errorMessage,
        });
      }
    }
  },
}

</script>
