<template>
  <div>
    <div>
      <FormField
        id="registration_number"
        label="SIRET"
        labelClass="font-normal"
        name="registration_number"
        required
        v-model="formData.registrationNumber"
      />
      <FormField
        id="billing_address"
        label="Adresse de facturation"
        labelClass="font-normal"
        name="billing_address"
        required
        v-model="formData.billingAddress"
      />

      <div>
        <label class="block text-sm leading-6 text-gray-900 sm:pt-1.5">
          RIB<span class="text-red-600">*</span>
        </label>
        <FileUploadArea
          v-bind:question="'IBAN ?'"
          v-bind:multiple="false"
          @files="updateRibFiles"
        >
          <FormField
            id="iban"
            placeholder="IBAN"
            name="iban"
            required
            v-model="formData.iban"
          />
        </FileUploadArea>
      </div>
      <hr>

      <div>
        <label class="block text-sm leading-6 text-gray-900 sm:pt-1.5">
          Pièce d’identité du mandataire social<span class="text-red-600">*</span>
        </label>
        <FileUploadArea
          v-bind:multiple="false"
          @files="updateIdentityDocumentFile"
        />
      </div>
      <hr>

      <div>
        <label class="block text-sm leading-6 text-gray-900 sm:pt-1.5">
          Kbis<span class="text-red-600">*</span>
        </label>
        <FileUploadArea
          v-bind:multiple="false"
          @files="updateKbisFile"
        />
      </div>
      <hr>
    </div>

    <div class="mt-4 flex justify-end">
      <Loading v-if="isLoading"/>
      <GenericButton v-else @click="updateKYC">
        Valider
      </GenericButton>
    </div>
  </div>
</template>

<script>
import GenericButton from "@/components/utils/GenericButton.vue";
import FormField  from "@/components/utils/FormField.vue";
import StoreService from "@/api/services/store";
import Loading from "@/components/utils/Loading.vue";
import FileUploadArea from "@/components/upload/FileUploadArea.vue";
import { mapActions } from "vuex";

export default {
  props: ["store"],

  components: {
    GenericButton,
    FormField,
    Loading,
    FileUploadArea
  },

  data() {
    return {
      isLoading: false,
      formData: {
        registrationNumber: "",
        billingAddress: "",
        iban: "",
        ribFile: null,
        identityDocumentFile: null,
        kbisFile: null,
      },
    };
  },

  created() {
    this.setupFormData();
  },
  emits: ['configurationUpdated'],
  methods: {
    ...mapActions("notifications", ["notify"]),
    async setupFormData() {
      this.isLoading = true;
      try {
        const { data } = await StoreService.getStoreKYC(this.store.id);
        this.formData.registrationNumber = data.registration_number;
        this.formData.billingAddress = data.billing_address;
        this.formData.iban = data.iban;
      } catch (error) {
        this.notify({
          category: "simple",
          type: "error",
          title: "Une erreur s'est produite lors du chargement du formulaire",
          text: error.response?.data?.error || error.message,
        });
      }
      this.isLoading = false;
    },
    async updateKYC() {
      const storeId = this.store.id;
      try {
        await StoreService.updateStoreKYC(storeId, this.formData);
        this.$emit("configurationUpdated");
        this.notify({
          category: "simple",
          type: "success",
          title: "Informations mises à jour",
          text: "Les KYC ont été mises à jour avec succès",
        });
      } catch (error) {
        this.notify({
          category: "simple",
          type: "error",
          title: "Une erreur s'est produite",
          text: error.response?.data?.error || error.message,
        });
      }
    },
    async updateRibFiles(files) {
      this.formData.ribFile = files[0];
    },
    async updateIdentityDocumentFile(file) {
      this.formData.identityDocumentFile = file[0];
    },
    async updateKbisFile(file) {
      this.formData.kbisFile = file[0];
    },
  }
};
</script>
